import React, { FC, FormEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/shared/lib/hooks/useAppDispatch';
import { selectAddProcessingError, selectAddProcessingIsLoading } from '../model/selector';
import { addProcessingActions } from '../model/slice';
import { Status } from '@/entities/status';
import { addNotificationFx } from '@/shared/lib/notifications/model/store';
import { Processing, processingsActions } from '@/entities/processing';
import { BASE_NEW_RATE_TYPE, RateType, RateTypeReference } from '@/entities/rate-type';
import { Button, FormControl, FormLabel, Typography } from '@mui/joy';
import { Recruitment, RecruitmentReference } from '@/entities/recruitment';
import { Vacancy, VacancyReference } from '@/entities/vacancy';
import { useAppSelector } from '@/shared/lib/hooks';
import { StatusReference } from '@/entities/status/ui/StatusReference';
import { EditableTextField } from '@/shared';
import { PartnerReference } from '@/entities/partner/ui/PartnerReference';
import { Partner } from '@/entities/partner';

export interface EditProcessingFormProps {
    processing?: Processing;
    recruitment?: Recruitment | Partial<Recruitment>;
    vacancy?: Vacancy | Partial<Vacancy>;
    onSuccess?: () => void;
}

export const EditProcessingForm: FC<EditProcessingFormProps> = ({
    processing,
    recruitment,
    vacancy,
    onSuccess
}) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectAddProcessingIsLoading);
    const error = useSelector(selectAddProcessingError);

    const rateTypes = useAppSelector(state => state.rateTypes.entities);
    const statuses = useAppSelector(state => state.statuses.entities);
    
    const [recruitmentState, setRecruitmentState] = React.useState<Partial<Recruitment> | undefined>(processing?.recruitment || recruitment || undefined);
    const [vacancyState, setVacancyState] = React.useState<Partial<Vacancy> | undefined>( processing?.vacancy || vacancy || undefined);
    const [statusState, setStatusState] = React.useState<Status>(processing?.status || statuses.find(status => status.weight === 0 && status.relation === 'processing') as Status);
    const [rateTypeState, setRateTypeState] = React.useState<RateType>(processing?.rate_type || rateTypes.find(rateType => rateType.id === BASE_NEW_RATE_TYPE) as RateType);
    const [commentState, setCommentState] = React.useState<string>(processing?.comment || '');
    const [rateState, setRateState] = React.useState<number>(processing?.rate || 0);
    const [managerState, setManagerState] = React.useState<Partial<Partner> | undefined>(processing?.manager || undefined);


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            dispatch(addProcessingActions.setLoading(true));
            if (processing?.id) {
                await dispatch(processingsActions.updateEntity({
                    id: processing.id,
                    data: {
                        status: statusState,
                        rate_type: rateTypeState,
                        recruitment: recruitmentState as Recruitment,
                        vacancy: vacancyState as Vacancy,
                        rate: rateState,
                        comment: commentState,
                        manager: managerState as Partner,
                    }
                })).unwrap();
                onSuccess?.();    
            } else {
                await dispatch(processingsActions.createEntity({
                    status: statusState,
                    rate_type: rateTypeState,
                    recruitment: recruitmentState as Recruitment,
                    vacancy: vacancyState as Vacancy,
                    rate: rateState,
                    comment: commentState,
                    manager: managerState as Partner
                })).unwrap();
                onSuccess?.();
            }
            
        } catch (error) {
            if (error instanceof Error) {
                dispatch(addProcessingActions.setError(error.message));
            } else {
                dispatch(addProcessingActions.setError('An unknown error occurred'));
            }
        } finally {
            dispatch(addProcessingActions.setLoading(false));
        }
    };

    useEffect(() => {
        if (error) {
            addNotificationFx({
                message: error,
                type: 'danger',
            });
        }
    }, [error]);

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <Typography className="text-2xl font-bold">
                {processing?.id ? 'Редактирование процессинга' : 'Добавление процессинга'}
            </Typography>
            <FormControl>
                <FormLabel htmlFor="name">Рекрутмент</FormLabel>
                <RecruitmentReference 
                    recruitment={recruitmentState as Recruitment || undefined}
                    blocked={recruitment ? true : false}
                    onApply={(v: Recruitment) => {setRecruitmentState(v);}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="name">Ответственный менеджер</FormLabel>
                <PartnerReference
                    partner={managerState as Partner || undefined}
                    onApply={(v: Partner) => {setManagerState(v);}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="name">Вакансия</FormLabel>
                <VacancyReference
                    vacancy={vacancyState as Vacancy || undefined}
                    blocked={vacancy ? true : false}
                    onApply={(v: Vacancy) => {setVacancyState(v);}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="name">Статус</FormLabel>
                <StatusReference
                    status={statusState}
                    statuses={statuses.filter(status => status.relation === 'processing')}
                    blocked={false}
                    onApply={(v: Status) => {setStatusState(v)}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="name">Тип рейта</FormLabel>
                <RateTypeReference
                    rateType={rateTypeState}
                    blocked={false}
                    onApply={(v: RateType) => {setRateTypeState(v)}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="rate">Рейт</FormLabel>
                <EditableTextField
                    value={rateState || 0}
                    placeholder="Введите рейт"
                    numeric={true}
                    onApply={(v: string |number) => {setRateState(v as number);}}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="comment">Комментарий</FormLabel>
                <EditableTextField
                    value={commentState}
                    placeholder="Введите комментарий"
                    numeric={false}
                    onApply={(v: string | number) => {setCommentState(v as string);}}
                />
            </FormControl>
            <FormControl className="flex justify-start max-w-96">
                <Button type="submit" color="primary" loading={isLoading}>
                    {processing?.id ? 'Сохранить' : 'Добавить'}
                </Button>
            </FormControl>

            {error && (
                <div className="text-red-500">
                    {error}
                </div>
            )}
        </form>
    );
};
