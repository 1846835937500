import { BaseService } from '@/shared/api/base';
import { CreateContactRequest, Contact as PartnerContact } from '../model/types';

class PartnerContactService extends BaseService<PartnerContact, CreateContactRequest> {
    constructor() {
        super('/partners/contacts');
    }
}

export const partnerContactService = new PartnerContactService();
