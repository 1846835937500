import { Partner, partnersActions } from "@/entities/partner";
import { EditableBadgeField, EditableTextField } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { Typography } from "@mui/joy";

interface PartnerHeadProps {
    blocked?: boolean;
}

export const PartnerHeadTitle = ({ blocked }: PartnerHeadProps) => {
    const partner = useAppSelector(state => state.partners.entity as Partner);
    const dispatch = useAppDispatch();
    const handleUpdatePartner = (value: string | number, field: string) => {
        if (!partner) return;
        dispatch(partnersActions.updateEntity({id: partner.id, data: {[field]: value}}));
    };
    return (
        <Typography component={'div'} className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {partner && <EditableTextField
            value={partner.name as string}
            blocked={blocked}
            onApply={(v) => {
                handleUpdatePartner(v, "title");
            }}
            />}
        </Typography>
    )
}

export const PartnerHeadActions = ({ blocked }: PartnerHeadProps) => {
    const partner = useAppSelector(state => state.partners.entity as Partner);
    const statuses = useAppSelector(state => state.statuses);
    const dispatch = useAppDispatch();
    const handleUpdatePartner = (value: string | number, field: string) => {
        if (!partner) return;
        dispatch(partnersActions.updateEntity({id: partner.id, data: {[field]: value}}));
    };

    return (
        <EditableBadgeField 
                value={partner.status?.id as number}
                blocked={blocked}
                badges={statuses.entities.filter(status => status.relation === "partner")}
                onApply={(v) => {
                    handleUpdatePartner(v, "status");
                }}
            />
    )
};