import { createSelector } from '@reduxjs/toolkit';
import { User } from './types';
import { RootState } from '@/app/providers/StoreProvider';

export const getUserState = (state: RootState) => state.users;

export const getUsersData = createSelector(
    getUserState,
    (state) => state?.entities ?? []
);

export const getUsersList = createSelector(
    getUsersData,
    (users) => Object.values(users) as User[]
);

export const getUserById = (id: number) => createSelector(
    getUsersData,
    (users) => users[id]
);

export const getUsersIsLoading = createSelector(
    getUserState,
    (state) => state?.loading ?? false
);
