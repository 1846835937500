import React, { FC, useState } from 'react';
import { HiPlus, HiViewBoards, HiViewList } from 'react-icons/hi';

import { Filter, getFiltred } from '@/shared/ui/filter';
import { TableWidget } from '@/widgets/views/TableWidget';
import { KanbanWidget } from '@/widgets/views/KanbanWidget';
import { StatusReference } from '@/entities/status/ui/StatusReference';
import { Status, StatusConstants, statusesActions } from '@/entities/status';
import { AdminPage } from '@/widgets/layouts/page/AdminPage';
import { IconButton, Button, Tooltip } from '@mui/joy';
import { useAppSelector } from '@/shared/lib/hooks/useAppSelector';
import { useEntityLoader } from '@/shared/lib/hooks/useEntityLoader';
import { useAppDispatch } from '@/shared/lib/hooks/useAppDispatch';
import { useUnit } from 'effector-react';
import { $filters } from '@/shared/ui/filter/store';
import { Recruitment, RecruitmentConstants, recruitmentsActions } from '@/entities/recruitment';
import { useSearchParams } from 'react-router-dom';
import { RecruitmentCard } from '@/entities/recruitment/ui/RecruitmentCard';

export const RecruitmentsPage: FC = () => {
  const filter = useUnit($filters)
  const dispatch = useAppDispatch();

  const [viewKanban, setViewKanban] = useState(false);
  const [ , setSearchParams] = useSearchParams();
  
  const [recruitmentsState, statusesState] = useEntityLoader<[Recruitment, Status]>([
    {
        state: useAppSelector(state => state.recruitments),
        action: recruitmentsActions,
        config: { entityName: RecruitmentConstants.title_ns }
    },
    {
        state: useAppSelector(state => state.statuses),
        action: statusesActions,
        config: { entityName: StatusConstants.title_ns }
    }
  ], 'RecruitmentsPage');

  // const setSelectedPartner = useCallback((partner: Partner) => {
  //   dispatch(partnersActions.selectEntity(partner));
  // }, []);

  // const handleCreatePartner = useCallback((data: CreatePartnerRequest) => {
  //   dispatch(partnersActions.createEntity(data));
  //   handleCloseModal();
  // }, [dispatch, handleCloseModal]);

  // const handleUpdatePartner = useCallback((data: CreatePartnerRequest) => {
  //   if (selectedPartner) {
  //     dispatch(partnersActions.updateEntity({ 
  //       id: selectedPartner.id, 
  //       data
  //     } ));
  //   }
  // }, [dispatch, selectedPartner]);

  // const handleEditPartner = useCallback((partner: Partner) => {
  //   setSelectedPartner(partner);
  //   setOpenModal(true);
  // }, []);

  return (
    <AdminPage
      loading={!!(recruitmentsState.loading || statusesState.loading) && !recruitmentsState.entities.length && !statusesState.entities.length}
      title={RecruitmentConstants.title_ns}
      buttonsBlock={
        <React.Fragment>
            <IconButton
              size="md"
              variant={viewKanban ? "solid" : "outlined"}
              onClick={() => setViewKanban(true)}
          >
              <HiViewBoards />
          </IconButton>
          <IconButton
              size="md"
              variant={viewKanban ? "outlined" : "solid"}
              onClick={() => setViewKanban(false)}
          >
              <HiViewList />
          </IconButton>
          <Button
              size="sm"
              onClick={() => setSearchParams({view: 'recruitment', id: 'new'})}
            >
              <HiPlus className="mr-2" />
              {RecruitmentConstants.acitons.create}
          </Button>
        </React.Fragment>
      }
    >
      <div>
          <Filter
            entity='recruitment'
            filters={[
            {
              column: 'status',
              label: 'Статус',
              type: 'select',
            },
            {
              column: 'recruiter',
              label: 'Рекрутер',
              type: 'select'
            },
          ]}
          data={recruitmentsState.entities}
          search={['name']}
          />
          {viewKanban ? (
          <KanbanWidget
            objects={filter ? getFiltred(filter, recruitmentsState.entities, 'recruitment') : recruitmentsState.entities}
            statuses={statusesState.entities}
            relation={RecruitmentConstants.relation}
            orderBy='created_at'
            maxWeight={100}
            children={(object: Recruitment) => <RecruitmentCard data={object}/>}
            onDragEnd={(id: number, statusId: number) => {
              dispatch(recruitmentsActions.updateEntity({ id, data: { status: statusesState.entities.find(status => status.id === statusId) } }));
            }}
          />
        ) : (
          <TableWidget
            objects={filter ? getFiltred(filter, recruitmentsState.entities, 'recruitment') : recruitmentsState.entities}
            renderCard={(recruitment: Recruitment) => <RecruitmentCard data={recruitment}/>}
            columns={[
              {name: 'id', alias: 'ID'},
              {name: 'employee', alias: 'Кандидат'},
              {name: 'recruiter', alias: 'Рекрутер'},
              {name: 'status', alias: 'Статус', mod: (id, value) => 
                <StatusReference 
                  status={value} 
                  statuses={statusesState.entities.filter(status => status.relation === RecruitmentConstants.relation)} 
                  onApply={(v: Status) => dispatch(recruitmentsActions.updateEntity({ id, data: { status: v } }))} 
                  blocked={false} />},
              {name: 'comment', alias: 'Комментарий', mod: (id: number, value: string) => <Tooltip title={value}><p>{value?.length < 15 ? value : value?.slice(0, 15) + '...'}</p></Tooltip>},
              {name: 'created_at', alias: 'Дата создания'},
            ]}
            orderableColumns={['created_at']}
            onEdit={(recruitment: Recruitment) => setSearchParams({view: 'recruitment', id: recruitment.id.toString()})}
            onDelete={(recruitment: Recruitment) => dispatch(recruitmentsActions.deleteEntity(recruitment.id))}
          />
        )}
        </div>
    </AdminPage>
  );
};
