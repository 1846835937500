import { configureStore } from '@reduxjs/toolkit';
import { statusesReducer } from '@/entities/status';
import { vacanciesReducer } from '@/entities/vacancy';
import { partnersReducer } from '@/entities/partner';
import { recruitmentsReducer } from '@/entities/recruitment';
import { scoringsReducer } from '@/entities/scoring';
import { processingsReducer } from '@/entities/processing';
import { stacksReducer } from '@/entities/stack';
import { rateTypesReducer } from '@/entities/rate-type';
import { partnerTypesReducer } from '@/entities/partner-type';
import { partnerContactsReducer } from '@/entities/partner-contact';
import { partnerRequisitesReducer } from '@/entities/partner-requisite';
import { partnerDocumentsReducer } from '@/entities/partner-document';
import { usersReducer } from '@/entities/user';
import { publicVacanciesReducer } from '@/entities/publicVacancies';

export const store = configureStore({
    reducer: {
        statuses: statusesReducer,
        vacancies: vacanciesReducer,
        publicVacancies: publicVacanciesReducer,
        partners: partnersReducer,
        recruitments: recruitmentsReducer,
        scorings: scoringsReducer,
        processings: processingsReducer,
        stacks: stacksReducer,
        rateTypes: rateTypesReducer,
        partnerTypes: partnerTypesReducer,
        partnerContacts: partnerContactsReducer,
        partnerRequisites: partnerRequisitesReducer,
        partnerDocuments: partnerDocumentsReducer,
        users: usersReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;