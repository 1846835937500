//ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Badge } from "flowbite-react";
import { HiCheck, HiChevronDown, HiPlus, HiQuestionMarkCircle, HiX } from "react-icons/hi";
import { Box, Menu, MenuButton, MenuItem, IconButton, Button, Input, List, ListItem, ListItemButton, ListDivider, Tooltip, CircularProgress, Dropdown, Textarea, Typography } from "@mui/joy";
import { EditableFieldProps, EditableTextFieldProps, EditableBadgeFieldProps, EditableLinkFieldProps, EditableMultiLinkFieldProps } from "./types";
import useDebounce from '../../lib/hooks/useDebounce';
import { flattenKeys, flattenValues } from '../../lib/utils';

const EditableField: React.FC<EditableFieldProps> = ({ value, renderEditMode, renderStaticMode, handleChange, ...props }) => {
  const [isEditing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => { setCurrentValue(value) }, [value]);

  return (
    <div {...props} onClick={() => setEditing(true)} style={{ cursor: "pointer" }}>
      {isEditing ? (
        <div>
          {renderEditMode(currentValue, setCurrentValue)}
        </div>
      ) : (
        <div>
          {renderStaticMode ? renderStaticMode(currentValue) : renderEditMode(currentValue, setCurrentValue)}
        </div>
      )}
    </div>
  );
};

const EditableTextField: React.FC<EditableTextFieldProps> = ({ 
  value, 
  onApply, 
  labelComponent, 
  blocked = false, 
  numeric, 
  className = "", 
  placeholder, 
  multiline, 
  activeDefault, 
  onCancel 
}) => {
  const Field = (props: any) => {
    const [isActive, setIsActive] = useState(!!activeDefault);
    const [val, setVal] = useState(value);
    const fieldRef = useRef<HTMLDivElement>(null);
    // const defaultValue = useRef(value);

    const handleCancel = () => {
      // if (fieldRef.current) {
      //   fieldRef.current.innerText = defaultValue.current?.toString() || placeholder || `Введите ${numeric ? 'число' : 'значение'}`;
      //   if (props.onChange && (props.value && fieldRef.current.innerText !== (placeholder || `Введите ${numeric ? 'число' : 'значение'}`))) {
      //     props.onChange(defaultValue.current);
      //   }
      // }
      setVal(value);
      setIsActive(false);
      if (onCancel) onCancel();
    };

    const handleSave = (v: string | number) => {
      // if (!fieldRef.current) return;
      
      // fieldRef.current.blur();
      setIsActive(false);
      if (v !== value) onApply(v);
    };

    // const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //   // Обработка числового ввода
    //   if (numeric) {
    //     const key = e.key;
    //     const isNavigationKey = key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Backspace' || key === 'Delete';
    //     const isDot = key === '.' || key === ',';
    //     const isNumber = /^\d$/.test(key);
        
    //     const shouldPreventDefault = (
    //       // Разрешаем навигационные клавиши
    //       !isNavigationKey && 
    //       // Разрешаем цифры
    //       !isNumber && 
    //       // Разрешаем одну точку
    //       !(isDot && !e.currentTarget.innerText.includes('.')) &&
    //       // Разрешаем выделение текста
    //       !e.ctrlKey && 
    //       !e.metaKey
    //     );

    //     if (shouldPreventDefault) {
    //       e.preventDefault();
    //       return;
    //     }
    //   }

    //   // Обработка сохранения по Enter
    //   if (e.key === 'Enter' && !e.shiftKey) {
    //     e.preventDefault();
    //     const value = e.currentTarget.innerText;
    //     handleSave(value);
    //   }
    // };

    // useEffect(() => {
    //   if (fieldRef.current) {
    //     fieldRef.current.innerText = props.value?.toString() || placeholder || `Введите ${numeric ? 'число' : 'значение'}`;
    //   }
    // }, [props.value]);

    // useEffect(() => {
    //   if (activeDefault && fieldRef.current) {
    //     fieldRef.current.focus();
    //   }
    // }, []);


    return (
      isActive 
        ? (
          multiline ? (
            <Textarea 
              className={props.className}
              slotProps={{
                textarea: {
                  className: "focus:ring-0 focus:ring-transparent",
                  sx: {
                    whiteSpace: "pre-wrap"
                  }
                }
              }}
              minRows={multiline ? 4 : 1}
              maxRows={multiline ? 100 : 1}
              value={val || placeholder}
              onChange={(e) => setVal(e.target.value)}
              placeholder={placeholder || `Введите ${numeric ? 'число' : 'значение'}`}
              endDecorator={
                <Box className="flex flex-row gap-1">
                  <Button onClick={() => handleCancel()} size="sm" variant="outlined">
                    Отменить
                  </Button>
                  <Button
                    onClick={() => fieldRef.current && onApply(fieldRef.current.innerText)}
                    disabled={!val || val === placeholder}
                    size="sm"
                  >
                    Сохранить
                  </Button>
                </Box>
                
              }
            />
          ) : (
            <Input 
              className={props.className}
              type={numeric ? "number" : "text"}
              slotProps={{
                input: {
                  className: "focus:ring-0 focus:ring-transparent"
                }
              }}
              value={val}
              placeholder={placeholder || `Введите ${numeric ? 'число' : 'значение'}`}
              onChange={(e) => setVal(e.target.value)}
              endDecorator={
                <Box className="flex-row top-0 right-1">
                  <IconButton onClick={() => handleCancel()} size="sm">
                    <HiX />
                  </IconButton>
                  <IconButton
                    onClick={() => val && handleSave(val)}
                    disabled={!val}
                    size="sm"
                  >
                    <HiCheck />
                  </IconButton>
                </Box>
              }
            />
          )
        )
        :<Box onClick={() => setIsActive(true)}>
          {value || value === 0 ? <Typography sx={{ whiteSpace: "pre-wrap" }}>{value}</Typography> : <Typography color="warning" level="body-sm" variant="plain">{placeholder || `Введите ${numeric ? 'число' : 'значение'}`}</Typography>} 
        </Box>
    )
    // return (
    //   <Box className={`flex-grow w-full p-1 rounded-md ${isActive ? 'bg-gray-300 focus:bg-transparent ' : 'hover:bg-gray-300 bg-transparent'} w-full ${props.className}`}>
    //     <Box
    //       ref={fieldRef}
    //       className="h-full"
    //       style={{
    //         whiteSpace: "pre-wrap",
    //       }}
    //       onClick={() => {
    //         setIsActive(true);
    //         if (!props.value && fieldRef.current && fieldRef.current.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение'}`)) {
    //           fieldRef.current.innerText = "";
    //         }
    //       }}
    //       onKeyDown={onKeyPress}
    //       onBlur={() => setIsActive(true)}
    //       suppressContentEditableWarning={true}
    //       {...props}
    //     />
    //     {isActive && !multiline && (
          // <Box className="flex-row top-0 right-1">
          //   <IconButton onClick={() => handleCancel()} size="sm">
          //     <HiX />
          //   </IconButton>
          //   <IconButton
          //     onClick={() => fieldRef.current && onApply(fieldRef.current.innerText)}
          //     disabled={!props.value && fieldRef.current?.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение'}`)}
          //     size="sm"
          //   >
          //     <HiCheck />
          //   </IconButton>
          // </Box>
    //     )}
    //     {isActive && multiline && (
    //       <Box className="flex flex-row mt-2 gap-2">
    //         <Button onClick={() => handleCancel()} size="sm" variant="outlined">
    //           Отменить
    //         </Button>
    //         <Button
    //           onClick={() => fieldRef.current && onApply(fieldRef.current.innerText)}
    //           disabled={!props.value && fieldRef.current?.innerText === (placeholder || `Введите ${numeric ? 'число' : 'значение'}`)}
    //           size="sm"
    //         >
    //           Сохранить
    //         </Button>
    //       </Box>
    //     )}
    //   </Box>
    // );
  };

  function renderStatic(currentValue: any) {
    return (
      <Box className={`w-full grid grid-cols-5 items-baseline ${className}`}>
        {labelComponent && (
          <Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>
        )}
        <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4" : "col-span-5 px-4"}>
          <Field
            value={currentValue}
            onChange={() => { }}
          />
        </Box>
      </Box>
    );
  }

  function renderDynamic(currentValue: any, setCurrentValue: (value: any) => void) {
    return (
      <Box className="w-full grid grid-cols-5 items-baseline">
        {labelComponent && (
          <Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>
        )}
        <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4" : "col-span-5 px-4"}>
          <Field
            contentEditable
            value={currentValue}
            onChange={setCurrentValue}
            className={className}
          />
        </Box>
      </Box>
    );
  }

  return (
    <EditableField
      value={value}
      renderEditMode={blocked ? renderStatic : renderDynamic}
      renderStaticMode={renderStatic}
    />
  );
};

const EditableBadgeField: React.FC<EditableBadgeFieldProps> = ({ value, onApply, badges, labelComponent, blocked = false }) => {
  function renderDynamic(currentValue: any, setCurrentValue: (value: any) => void) {
    return (
      <Box className="w-full grid grid-cols-5 items-center">
        {labelComponent && (
          <Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>
        )}
        {badges && (
          <Box className={labelComponent ? "col-span-3 md:col-span-4" : "col-span-5"}>
            <Dropdown>
              <MenuButton variant="plain">
                <Badge
                  className="w-full h-full"
                  key={badges?.find((b) => b.id === currentValue)?.id}
                  color={badges?.find((b) => b.id === currentValue)?.color}
                >
                  <div className="flex flex-row place-items-center">
                    {badges?.find((b) => b.id === currentValue)?.name}
                    <HiChevronDown className="ml-2" size={14} />
                  </div>
                </Badge>
              </MenuButton>
              <Menu>
                {badges.map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={() => {
                      setCurrentValue(option.id);
                      onApply(option.id);
                    }}
                  >
                    <Badge key={option.id} color={option.color}>
                      {option.name}
                    </Badge>
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </Box>
        )}
      </Box>
    );
  }

  function renderStatic(currentValue: any) {
    return renderDynamic(currentValue, () => { });
  }

  return (
    <EditableField
      value={value}
      renderEditMode={blocked ? renderStatic : renderDynamic}
      renderStaticMode={renderStatic}
    />
  );
};

// Example usage for editable link field
const EditableLinkField: React.FC<EditableLinkFieldProps> = ({ value, labelComponent, onApply, onSearch, onCreate, blocked=false, onClick }) => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<any>(null);

  const debaunceRequest = useDebounce(async (r: any, q: string) => {
    setSearchLoader(true);
    if (onSearch) {
      let result = await onSearch(q);
      result.map((res) => ({
        id: flattenKeys(res),
        value: flattenValues(res),
      }));
      setSearchResults(r.concat(result));
      setSearchLoader(false);
    }
  }, 500)

  const handleCreate = async (value: object) => {
    if (onCreate) {
      const newData = await onCreate(flattenValues(value) as string);
      handleSelect({id: flattenKeys(newData), name: flattenValues(newData)});
    }
  }

  const handleSearch = async (q: string) => {
    setQuery(q);
    if (q.length > 1) {
      let results = [];
      if (onCreate) {
        results.push({ id: null, value: q });
        setSearchResults(results)
      }
      debaunceRequest(results, q);
    }
    else {
      setSearchResults([]);
    }
  };

  const handleSelect = (val: any) => {
    if (val !== newValue) {
      setNewValue(val);
      setQuery("");
      setSearchResults([]);
    }
  }

  const deleteValue = () => {
    setNewValue({});
  }

  useEffect(() => {
    setNewValue(value);
  }, [value])

  return (
    <Box className="w-full grid grid-cols-5 items-center">
          {labelComponent && <Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>}
          <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4 gap-2" : "col-span-5 px-4 gap-2"} sx={{
                display: isOpen ? "none" : "Flex"
              }} onClick={blocked ? () => onClick && onClick(value?.id) : () => setIsOpen(true)}>
            {newValue && newValue.id ?
              <Badge 
                className="w-fit h-full"
                color="info"
              >
                <div className="flex flex-row place-items-center">
                  {flattenValues(newValue)}
                </div>
              </Badge> : !blocked && <IconButton size="sm" onClick={blocked ? () => onClick && onClick(value?.id) : () => setIsOpen(true)} variant="soft"><HiPlus /></IconButton>
            }
          </Box>
          <Box
            className="col-span-5 flex-col gap-2"
              sx={{
                display: isOpen ? "flex" : "none"
              }}
            >
              <Box className="w-full flex flex-row">
                <Input
                  value={query}
                  size="sm"
                  className="w-full"
                  placeholder="Введите текст для поиска"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {searchLoader && <CircularProgress size="sm" />}
                {query.length > 1 && !searchLoader && <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {setQuery(""); handleSearch("")}}
                >
                    <HiX/>
                </IconButton>}
              </Box>
              {searchResults.length > 0 && (
                  <List className="w-full flex flex-row">
                    {searchResults.map((res, index) => 
                      <ListItem key={index}>
                        <ListItemButton onClick={() => !res.id && onCreate ? handleCreate(res) : handleSelect(res)}>
                          {res.id ? flattenValues(res) || "" : `${flattenValues(res)} - добавить значение`}
                        </ListItemButton>
                      </ListItem>
                    )}
                    <ListDivider />
                  </List>
              )}
              <Box className="w-full flex flex-row gap-2">
                {newValue && flattenValues(newValue) &&
                  <Badge 
                    className="w-fit h-full"
                    color="info"
                  >
                    <div className="flex flex-row place-items-center gap-4">
                      {flattenValues(newValue)}
                      <IconButton
                        size="sm"
                        variant="plain"
                        onClick={() => deleteValue()}
                      >
                          <HiX/>
                      </IconButton>
                    </div>
                  </Badge>
                }
              </Box>
              <Box className="w-full flex flex-row gap-4">
                <Button variant="soft" size="sm" onClick={
                  () => {setNewValue(value); setIsOpen(false)}
                }>
                  Отменить
                </Button>
                <Button variant="soft" disabled={value===newValue} size="sm" onClick={() => {onApply(newValue); setIsOpen(false)}}>
                  Сохранить
                </Button>
              </Box>
          </Box>
    </Box>
  );
};

// Example usage for editable link field
const EditableMultiLinkField: React.FC<EditableMultiLinkFieldProps> = ({ value, labelComponent, onApply, onSearch, searchInfo, onCreate, colored, blocked=false }) => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [newValues, setNewValues] = useState<any[]>([]);

  const debaunceRequest = useDebounce(async (r: any, q: string) => {
    setSearchLoader(true);
    if (onSearch) {
      let result = await onSearch(q);
      let newResult = result.filter((res) => !value.map((v) => flattenKeys(v)).includes(flattenKeys(res))).map((res) => ({
        id: flattenKeys(res),
        value: flattenValues(res),
      }));
      setSearchResults(r.concat(newResult));
      setSearchLoader(false);
    }
  }, 500)

  const handleSearch = async (q: string) => {
    setQuery(q);
    if (q.length > 1) {
      let results = [];
      if (onCreate) {
        results.push({ id: null, value: q });
        setSearchResults(results)
      }
      debaunceRequest(results, q);
    }
    else {
      setSearchResults([]);
    }
  };

  const handleCreate = async (value: any) => {
    if (!onCreate) return;
    const newData = await onCreate(flattenValues(value) as string);
    handleSelect({id: flattenKeys(newData), name: flattenValues(newData)});
  }

  const handleSelect = (val: any) => {
    let temp_values = newValues;
    if (temp_values.filter(v => val === v).length === 0) {
      setNewValues([...temp_values, val]);
      setQuery("");
      setSearchResults([]);
    }
  }

  const deleteValue = (id: any) => {
    setNewValues(newValues.filter(val => flattenKeys(val) !== id));
  }

  useEffect(() => {
    setNewValues(value ? value.slice() : []);
  }, [value])

  return (
    <Box className="w-full grid grid-cols-5 items-center">
          {labelComponent && <Box className="col-span-2 md:col-span-1">
            {labelComponent}
          </Box>}
          <Box className={labelComponent ? "col-span-3 md:col-span-4 px-4 gap-2 flex-col" : "col-span-5 px-4 gap-2 flex-col"} sx={{
                display: isOpen ? "none" : "Flex"
              }} onClick={blocked ? () => {} : () => setIsOpen(true)}>
            {newValues && newValues.length > 0 ? newValues.map((val, index) => (
              <Badge 
                key={index}
                className="w-fit h-full"
                color={colored ? colored(val) : "info"}
              >
                <div className="flex flex-row place-items-center">
                  {flattenValues(val)}
                </div>
              </Badge>
            )) : !blocked && <IconButton size="sm" onClick={blocked ? () => {} : () => setIsOpen(true)} variant="soft"><HiPlus /></IconButton>}
            
          </Box>
          <Box
            className="col-span-5 flex-col gap-2"
              sx={{
                display: isOpen ? "flex" : "none"
              }}
            >
              <Box className="w-full flex flex-row">
                {searchInfo && <Tooltip title={searchInfo} variant="plain">
                  <IconButton variant="plain"><HiQuestionMarkCircle /></IconButton>
                </Tooltip>}
                <Input
                  value={query}
                  size="sm"
                  className="w-full"
                  placeholder="Введите текст для поиска"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {searchLoader && <CircularProgress size="sm" />}
                {query.length > 1 && !searchLoader && <IconButton
                  size="sm"
                  variant="plain"
                  onClick={() => {setQuery(""); handleSearch("")}}
                >
                    <HiX/>
                </IconButton>}
              </Box>
              {searchResults.length > 0 && (
                  <List className="w-full flex flex-row">
                    {searchResults.map((res, index) => 
                      <ListItem key={index}>
                        <ListItemButton onClick={() => !res.id && onCreate ? handleCreate(res) : handleSelect(res)}>
                          {colored 
                          ? <Badge color={colored(res)}>{res.id ? flattenValues(res) : `${flattenValues(res)} - добавить значение`}</Badge>
                          : <div>
                            {res.id ? flattenValues(res) : `${flattenValues(res)} - добавить значение`}
                          </div>}
                        </ListItemButton>
                      </ListItem>
                    )}
                    <ListDivider />
                  </List>
              )}
              <Box className="w-full flex flex-col gap-1">
                {newValues && newValues.map((val, index) => (

                  flattenValues(val) && <Badge 
                    key={index}
                    className="w-fit h-full"
                    color={colored ? colored(val) : "info"}
                  >
                    <div className="flex flex-row place-items-center gap-4">
                      {flattenValues(val)}
                      <IconButton
                        size="sm"
                        variant="plain"
                        onClick={() => deleteValue(flattenKeys(val))}
                      >
                          <HiX/>
                      </IconButton>
                    </div>
                  </Badge>
                ))}
              </Box>
              <Box className="w-full flex flex-row gap-4">
                <Button variant="soft" size="sm" onClick={
                  () => {setNewValues(value); setIsOpen(false)}
                }>
                  Отменить
                </Button>
                <Button variant="soft" disabled={JSON.stringify(value)===JSON.stringify(newValues)} size="sm" onClick={() => {onApply(newValues); setIsOpen(false)}}>
                  Сохранить
                </Button>
              </Box>
          </Box>
    </Box>
  );
};

export { EditableTextField, EditableBadgeField, EditableLinkField, EditableMultiLinkField };
