import { BaseService } from '@/shared/api/base';
import { Recruitment, CreateRecruitmentRequest } from '../model/types';

class RecruitmentService extends BaseService<Recruitment, CreateRecruitmentRequest> {
    constructor() {
        super('/recruitments');
    }
}

export const recruitmentService = new RecruitmentService();
