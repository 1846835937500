import { BaseService } from '../../../shared/api/base';
import { RateType, CreateRateTypeRequest } from '../model/types';

class RateTypesService extends BaseService<RateType, CreateRateTypeRequest> {
    constructor() {
        super('/utils/rate_types');
    }
}

export const rateTypesService = new RateTypesService();
