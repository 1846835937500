const ns = "status";

const acitons = {
    create: "Создать статус",
    edit: "Редактировать статус",
    view: "Просмотреть статус",
};

// namespace title
const title_ns = "Статусы";

const title = "Статус";

export const StatusConstants = {
    ns,
    title_ns,
    title,
    acitons,
};