import React, { useState } from "react";
import { flattenKeys, flattenValues } from "../../lib/utils";
import { Box, Button, Divider, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, ModalDialog, Option, Select, Sheet, Typography } from "@mui/joy";
import { HiFilter, HiSearch, HiX } from "react-icons/hi";
import { FilterProps, PreparedFilter, SearchFilter } from "./types";
import { useUnit } from "effector-react";
import { $filters, addFilter, removeFilter, updateFilter } from "./store";

const getFiltred = (filter: PreparedFilter[], arr: any[], entity: string) => arr.filter(row => {
    return filter.filter(f => f.entity === entity).every(f => {
        if (f.column !== "_search") {
            const rowValue = row[f.column];
            if (Array.isArray(f.value)) {
                if (f.value.length === 0) return true
                if (Array.isArray(rowValue)) {
                    return rowValue.some(val => f.value.includes(flattenKeys(val)))
                }
                return f.value.includes(flattenKeys(rowValue));
            } else if (typeof f.value === 'number') {
                return rowValue <= f.value;
            }
            return false;
        } else {
            const searchValue = f.value as SearchFilter;
            const searchColumns = searchValue.columns;
            const value = searchValue.value;
            return searchColumns.some(column => 
                    {
                        if (typeof row[column] === 'string') {
                            return row[column].toLowerCase().includes(value.toLowerCase())
                        } else if (Array.isArray(row[column])) {
                            return row[column].some((val: any) => {
                                if (typeof val === 'string') {
                                    return val.toLowerCase().includes(value.toLowerCase())
                                }
                                return (flattenValues(val) as string).toLowerCase().includes(value.toLowerCase());
                            })
                        } else if (typeof row[column] === 'number') {
                            return row[column].toString().includes(value);
                        }
                        return false    
                    });
        };
    });
});

const Filter: React.FC<FilterProps> = ({ filters, data, search, entity }) => {
    const filter = useUnit($filters)
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleFilterChange = (value: PreparedFilter) => {
        let updated = false;
        filter.forEach((element, index) => {
            if (element.column === value.column) { updateFilter(value); updated = true};
        });
        if (!updated) {
            addFilter(value)
        }
    }

    const extractUniqueValues = (key: string | number, flatten=false) => {
        function onlyUnique(value: object, index: number, array: object[]) {
            if (typeof value === 'object' && value !== null) {
                const local_key = flattenKeys(value);
                return array.findIndex(item => flattenKeys(item) === local_key) === index;
            }
            return array.indexOf(value) === index;
        }
          
        const values = new Set(data.map(row => row[key]));
        let difvalues: any[] = [];
        Array.from(values).forEach(value => {
            function pushVal(val: any) {
                if (!difvalues.includes(val)) difvalues.push(val);
            }
            if (Array.isArray(value)) value.forEach(v => pushVal(v))
            else pushVal(value)
        });
        return difvalues
            .filter(onlyUnique)
            .sort((a, b) => {
                if (flatten) return a-b;
                const valueA = flattenValues(a);
                const valueB = flattenValues(b);
                
                // Type guard to ensure we only call localeCompare on strings
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueA.localeCompare(valueB);
                }
                
                // Fallback comparison for non-string types
                return String(valueA).localeCompare(String(valueB));
            })
            .map(value => 
            {
                if (flatten) return flattenValues(value)
                if (flattenValues(value)) return <Option key={String(flattenKeys(value))} value={flattenKeys(value)}>{flattenValues(value)}</Option>
            }
        );
      };

    const renderFilters = () => (
        <React.Fragment>
          {filters.map((local_filter) => (
            <FormControl size="sm" key={local_filter.column}>
              <FormLabel>{local_filter.label}</FormLabel>
    
              {local_filter.type && ["range"].includes(local_filter.type)
              ? (
                local_filter.type === "range" && <Input
                    type="number"
                    defaultValue={Math.max(...extractUniqueValues(local_filter.column, true).map(val => {if (typeof val === 'number') return val; else return 0}))}
                    onChange={(event) => {
                        const value = event.target.value;
                        if (!value) {
                            const maxValue = Math.max(...extractUniqueValues(local_filter.column, true).map(val => {if (typeof val === 'number') return val; else return 0}));
                            event.target.value = maxValue.toString();
                            handleFilterChange({column: local_filter.column, value: maxValue, entity: entity});
                        } else {
                            const parsedValue = parseInt(value, 10);
                            if (!isNaN(parsedValue)) {
                                handleFilterChange({column: local_filter.column, value: parsedValue, entity: entity});
                            }
                        }
                    }}
                    slotProps={{
                        input: {
                            min: Math.min(...extractUniqueValues(local_filter.column, true).map(val => {if (typeof val === 'number') return val; else return 0})) - 100,
                            max: Math.max(...extractUniqueValues(local_filter.column, true).map(val => {if (typeof val === 'number') return val; else return 0})) + 100,
                            step: 100
                        }
                    }}
                />
              )
              : <Select
                multiple
                size="sm"
                value={filter.find(f => f.column === local_filter.column)?.value || []}
                placeholder={local_filter.placeholder}
                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                onChange={(event, newValue) => handleFilterChange({column: local_filter.column, value: newValue, entity: entity})}
              >
                {extractUniqueValues(local_filter.column)}
              </Select>}
            </FormControl>
          ))}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {filters && (<Sheet
            className="SearchAndFilters-mobile"
            sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
        >
            {search && <Input
                size="sm"
                placeholder="Поиск"
                value={searchValue}
                onChange={(event) => {
                    setSearchValue(event.target.value);
                    handleFilterChange({column: '_search', value: {columns: search, value: event.target.value}, entity: entity});
                }}
                startDecorator={<HiSearch />}
                endDecorator={
                <Button variant="plain" color="neutral"
                    onClick={() => {
                        removeFilter('_search');
                    }}
                >
                    <HiX/>
                </Button>}
                sx={{ flexGrow: 1 }}
            />}
            <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={() => setOpen(true)}
            >
                <HiFilter />
            </IconButton>
            <Modal open={open} onClose={() => setOpen(false)} sx={{marginTop: "var(--Header-height)"}}>
                <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                    <ModalClose />
                    <Typography id="filter-modal" level="h2">
                    Фильтры
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {renderFilters()}
                    <Button color="primary" onClick={() => setOpen(false)}>
                        Закрыть
                    </Button>
                    </Sheet>
                </ModalDialog>
            </Modal>
        </Sheet>)}
        {filters && <Box
            className="SearchAndFilters-tabletUp"
            sx={{
            borderRadius: 'sm',
            py: 2,
            display: { xs: 'none', sm: 'flex' },
            flexWrap: 'wrap',
            gap: 1.5,
            '& > *': {
                minWidth: { xs: '120px', md: '160px' },
            },
            }}
        >
            {search && <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Поиск</FormLabel>
                <Input 
                    size="sm" 
                    value={searchValue}
                    placeholder="Поиск" startDecorator={<HiSearch />} 
                endDecorator={
                    <Button variant="plain" color="neutral"
                        onClick={() => {
                            removeFilter('_search');
                        }}
                    >
                        <HiX/>
                    </Button>}
                onChange={(event) => {
                    setSearchValue(event.target.value);
                    handleFilterChange({column: '_search', value: {columns: search, value: event.target.value}, entity: entity});
                }}
                />
            </FormControl>}
            {renderFilters()}
        </Box>}
        </React.Fragment>
    );
};

export { Filter, getFiltred };