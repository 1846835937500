import { FC, FormEvent } from 'react';
import { Label, TextInput } from 'flowbite-react';
import { Button } from '@mui/joy';
import { Requisite } from '../model/types';

export interface RequisiteFormProps {
    id?: number;
    onSubmit: (data: Partial<Requisite> | Requisite) => Promise<void>;
    onDecline?: () => void;
    initialData: Requisite | Partial<Requisite>;
    isLoading?: boolean;
}

export const RequisiteForm: FC<RequisiteFormProps> = ({
    id,
    onSubmit,
    initialData,
    isLoading,
    onDecline,
}) => {
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        
        await onSubmit({
            name: formData.get('name') as string,
            value: formData.get('value') as string,
            partner_id: initialData.partner_id,
            ...(id && {id})
        });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Имя реквизита" />
                </div>
                <TextInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Имя реквизита"
                    required
                    disabled={isLoading}
                    defaultValue={initialData?.name}
                />
            </div>     
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="value" value="Значение" />
                </div>
                <TextInput
                    id="value"
                    name="value"
                    type="value"
                    placeholder="Значение"
                    disabled={isLoading}
                    defaultValue={initialData?.value}
                />
            </div>
            <div className="col-span-2 flex justify-end gap-2">
                <Button
                    type="button"
                    onClick={onDecline}
                    disabled={isLoading}
                    color="neutral"
                >
                    Отмена
                </Button>
                
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
