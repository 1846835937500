import { TableAdmin } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { partnerDocumentsActions } from "../model/slice";
import { Box, IconButton } from "@mui/joy";
import { HiClipboardCopy, HiDocumentDownload } from "react-icons/hi";
import { useEffect } from "react";

interface DocumentsTableProps {
    partnerId: number,
    editable?: boolean
}

export const DocumentsTable = ({ partnerId, editable }: DocumentsTableProps) => {
    const dispatch = useAppDispatch();

    const partnerDocumentsState = useAppSelector(state => state.partnerDocuments);
    
    useEffect(() => {
        console.log(partnerDocumentsState.entities);
    }, [partnerDocumentsState.entities]);

    return (
        partnerDocumentsState.entities &&
        <TableAdmin 
            data={partnerDocumentsState.entities.filter(c => c.partner_id === partnerId)}
            columns={[
                {name: 'name', alias: 'Имя'},
                {name: 'download_url', alias: 'Действия', mod: (id: number, value: any) => 
                <Box className="flex gap-2">
                <IconButton size="md" variant="soft" component="a" target="__blank" href={value}><HiDocumentDownload/></IconButton>
                <IconButton size="md" variant="soft" onClick={() => {navigator.clipboard.writeText(value); alert("Ссылка скопирована в буфер обмена")}}><HiClipboardCopy/></IconButton>
                </Box>},
            ]}
            {...editable && {onDelete: (id: string |number) => dispatch(partnerDocumentsActions.deleteEntity(id as number))}}
        />
    )
};