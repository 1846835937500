import { Status } from "@/entities/status";
import { flattenValues } from "@/shared";
import { Kanban } from "@/shared/ui/kanban";
import React from "react";

interface BaseObject {
    id: number;
    status_id?: number;
    status?: Status;
}

interface KanbanProps<T extends BaseObject> {
    objects: T[];
    statuses: Status[];
    orderBy?: keyof T;
    orderDirection?: 'asc' | 'desc';
    maxWeight: number;
    relation: string;
    children: (object: T) => React.ReactNode | JSX.Element;
    onDragEnd: (objectId: number, statusId: number) => void;
}

export function KanbanWidget<T extends BaseObject>({ objects, statuses, orderBy, orderDirection, maxWeight, relation, children, onDragEnd }: KanbanProps<T>) {

    return (
        <Kanban 
            columns={statuses
                .filter((status: Status) => status.relation === relation && status.weight <= maxWeight)
                .sort((a: Status, b: Status) => a.weight - b.weight)
                .map((status: Status) => ({
                    id: status.id,
                    title: status.name,
                    color: status.color,
                    cards: objects
                        .filter((object: T) => object.status_id === status.id || object.status?.id === status.id)
                        .sort((a: T, b: T) => {
                            if (orderBy) {
                                if (orderDirection === 'asc') {
                                    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
                                        return (a[orderBy] as string).localeCompare(b[orderBy] as string);
                                    } else {
                                        return a[orderBy] > b[orderBy] ? 1 : -1;
                                    }
                                } else {
                                    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
                                        return (a[orderBy] as string).localeCompare(b[orderBy] as string);
                                    } else {
                                        return a[orderBy] > b[orderBy] ? 1 : -1;
                                    }
                                }
                            } return 0
                        })
                        .map((object: T) => ({
                            id: object.id,
                            title: flattenValues(object),
                            child: children(object)
                        }))
                }))
            }
            onDragEnd={(cardId: string, colId: string) => onDragEnd(parseInt(cardId), parseInt(colId))}
        />
    )
};