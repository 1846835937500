import { BaseService } from '../../../shared/api/base';
import { PublicVacancy } from '../model/types';

class PublicVacancyService extends BaseService<PublicVacancy, Partial<PublicVacancy>> {
    constructor() {
        super('/vacancies/public');
    }
}

export const vacancyService = new PublicVacancyService();
