// @ts-ignore
// @ts-nocheck
import React, { useState } from 'react';
import { Box, Chip, IconButton, List, ListItemButton, ListItemContent } from '@mui/joy';
import { HiChevronDown, HiChevronUp, HiCode, HiRefresh, HiViewList, HiXCircle } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks';
import { ListItem } from 'flowbite-react';
import { statusesActions } from '@/entities/status';
import { vacanciesActions } from '@/entities/vacancy';
import { partnersActions } from '@/entities/partner';
import { recruitmentsActions } from '@/entities/recruitment';
import { scoringsActions } from '@/entities/scoring';
import { processingsActions } from '@/entities/processing';
import { stacksActions } from '@/entities/stack';
import { rateTypesActions } from '@/entities/rate-type';
import { partnerTypesActions } from '@/entities/partner-type';
import { partnerContactsActions } from '@/entities/partner-contact';
import { partnerRequisitesActions } from '@/entities/partner-requisite';
import { partnerDocumentsActions } from '@/entities/partner-document';
import { usersActions } from '@/entities/user';
import { flattenValues } from '@/shared';
import { useUnit } from 'effector-react';
import { $authStore } from '@/processes/auth';

export const StateManager = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [storeOpen, setStoreOpen] = useState(false);
    const [authOpen, setAuthOpen] = useState(false);

    const stores = useAppSelector(state => state);
    const authState = useUnit($authStore)
    const dispatch = useAppDispatch();
    const actions = {
        'statuses': statusesActions,
        'vacancies': vacanciesActions,
        'partners': partnersActions,
        'recruitments': recruitmentsActions,
        'scorings': scoringsActions,
        'processings': processingsActions,
        'stacks': stacksActions,
        'rateTypes': rateTypesActions,
        'partnerTypes': partnerTypesActions,
        'partnerContacts': partnerContactsActions,
        'partnerRequisites': partnerRequisitesActions,
        'partnerDocuments': partnerDocumentsActions,
        'users': usersActions,

    }

    if (process.env.NODE_ENV !== 'development') {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                zIndex: 9999,
            }}
        >
            {!isOpen ? (
                <IconButton
                    onClick={() => setIsOpen(true)}
                    variant="solid"
                    color="primary"
                >
                    <HiCode />
                </IconButton>
            ) : (
                <Box
                    sx={{
                        backgroundColor: 'background.body',
                        padding: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                        maxWidth: 450,
                        maxHeight: '80vh',
                        overflow: 'auto',
                    }}
                >
                    <Box className="flex justify-between items-center mb-2 gap-2">
                        <h3 className="text-lg font-bold">State Manager</h3>
                        <IconButton
                            onClick={() => setIsOpen(false)}
                            size="sm"
                            variant="plain"
                        >
                            <HiXCircle />
                        </IconButton>
                    </Box>
                    <List className="space-y-2">
                        <ListItem>
                            <ListItemButton className='font-medium ' onClick={() => setStoreOpen(!storeOpen)}>
                                Entities
                                {storeOpen ? (
                                    <HiChevronDown />
                                ) : (
                                    <HiChevronUp />
                                )}
                            </ListItemButton>
                        </ListItem>
                        {storeOpen && <ListItem>
                            <ListItemContent>
                                <List>
                                    {Object.entries(stores).map(([storeName, value]) => (
                                        <ListItem key={storeName} className="border-b pb-2">
                                            <ListItemContent>
                                                <Box className="flex flex-row justify-between gap-2">
                                                    <div>{storeName}</div>
                                                    <div>
                                                        {value.loading && (
                                                            <Chip
                                                                size="sm"
                                                                color="primary"
                                                                variant="soft"
                                                            >
                                                                Loading
                                                            </Chip>
                                                        )}
                                                        <Chip
                                                            size="sm"
                                                            color="primary"
                                                            variant="soft"
                                                        >
                                                            {value.entities.length}
                                                        </Chip>
                                                        <IconButton
                                                            onClick={() =>
                                                            {
                                                                console.log(value);
                                                            }}
                                                            size="sm"
                                                            variant="plain"
                                                        >
                                                            <HiViewList />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>   
                                                            {
                                                                dispatch(actions[storeName].fetchAll());
                                                            }}
                                                            size="sm"
                                                            variant="plain"
                                                        >
                                                            <HiRefresh />
                                                        </IconButton>
                                                    </div>
                                                </Box>
                                                <List>
                                                    <ListItem>
                                                        <ListItemContent>
                                                            Last updated: {value.lastUpdated}
                                                        </ListItemContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemContent className="flex flex-row justify-between gap-2">
                                                            Entity: {flattenValues(value.entity)}
                                                            {value.entity && <IconButton
                                                                onClick={() => {
                                                                    console.log(value.entity);
                                                                }}
                                                                size="sm"
                                                                variant="plain"
                                                            >
                                                                <HiViewList />
                                                            </IconButton>}
                                                        </ListItemContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemContent>
                                                            Error: {value.error}
                                                        </ListItemContent>
                                                    </ListItem>
                                                </List>    
                                            </ListItemContent>
                                            
                                        </ListItem>
                                    ))}
                                </List>    
                            </ListItemContent>
                        </ListItem>}
                        <ListItem>
                            <ListItemButton className='font-medium ' onClick={() => setAuthOpen(!authOpen)}>
                                AuthState
                                {authOpen ? (
                                    <HiChevronDown />
                                ) : (
                                    <HiChevronUp />
                                )}
                            </ListItemButton>
                        </ListItem>
                        {authOpen && <ListItem>
                            <ListItemContent>
                                <List>
                                    <ListItem>
                                        <ListItemContent>
                                            <List>
                                                <ListItem>
                                                        is Authenticated: {authState.isAuthenticated ? "True" : "False"}
                                                </ListItem>
                                                <ListItem>
                                                        User:
                                                            {authState.user && (<List marker='circle'>
                                                                <ListItem>
                                                                    keycloak_id: {authState.user.keycloak_id}
                                                                </ListItem>
                                                                <ListItem>
                                                                    login: {authState.user.email}
                                                                </ListItem>
                                                                <ListItem>
                                                                    roles: {authState.user.roles.join(', ')}
                                                                </ListItem>
                                                            </List>)}
                                                </ListItem>
                                            </List>
                                        </ListItemContent>
                                    </ListItem>
                                </List>    
                            </ListItemContent>
                        </ListItem>}
                    </List>
                </Box>
            )}
        </Box>
    );
};
