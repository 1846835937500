import { Processing, ProcessingConstants, processingsActions } from "@/entities/processing";
import { Recruitment, RecruitmentConstants, recruitmentsActions } from "@/entities/recruitment";
import { ViewProcessings } from "@/features/processing";
import { RecruitmentView, RecruitmentHeadActions, RecruitmentHeadTitle } from "@/features/recruitment";
import { useAppDispatch, useAppSelector, useEntityLoader } from "@/shared/lib/hooks";
import { DrawerWidget } from "@/widgets/layouts/drawer/ui/Drawer";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom"

export const RecruitmentDrawer = () => {
    const [ searchParams ] = useSearchParams();
    const dispatch = useAppDispatch();

    // Загружаем все данные для Drawer
    const [recruitments, ] = useEntityLoader<[Recruitment, Processing]>([
        {
            state: useAppSelector(state => state.recruitments),
            action: recruitmentsActions,
            config: { entityName: RecruitmentConstants.title }
        },
        {
            state: useAppSelector(state => state.processings),
            action: processingsActions,
            config: { entityName: ProcessingConstants.title_ns }
        },
    ], 'RecruitmentView');

    const recruitment = useAppSelector(state => state.recruitments.entity);
    const statusesState = useAppSelector(state => state.statuses);
    
    useEffect(() => {
        if (searchParams.get('view') === 'recruitment' && searchParams.get('id')) {
            if (searchParams.get('id') === 'new') {
                dispatch(recruitmentsActions.createEmptyEntity({
                    name: "Новая задача рекрутмента",
                    status: statusesState.entities.find(status => status.relation === RecruitmentConstants.relation && status.weight === 0),
                } as Recruitment));
            } else {
                dispatch(recruitmentsActions.selectEntity({id: Number(searchParams.get('id'))}));
                // dispatch(vacanciesActions.fetchOne(Number(searchParams.get('id'))));    
            }
            
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, recruitments.lastUpdated])

    if (searchParams.get('view') === 'recruitment' && searchParams.get('id')) {
        return (
            recruitment &&
            <DrawerWidget 
                isOpen={searchParams.get('view') === 'recruitment' && searchParams.get('id') ? true : false}
                title={<RecruitmentHeadTitle />}
                topRightHeader={<RecruitmentHeadActions />}
                actions={{
                    clearEntity: recruitmentsActions.clearEntity,
                }}
                childs={[
                    <RecruitmentView key={Number(searchParams.get('id'))} id={Number(searchParams.get('id'))} editable={true} />,
                    <ViewProcessings key={'proc_' + searchParams.get('id')} recruitment={recruitment as Recruitment} />
                ]}
            />
        )
    }
    return null
};