import { 
    createSlice, 
    createAsyncThunk,
    PayloadAction,
} from '@reduxjs/toolkit';
import { Draft } from 'immer';
import { addNotificationFx } from '../notifications/model/store';

export interface BaseState<T extends { id: string | number }> {
    entities: T[];
    entity: T | Partial<T> | null;
    loading: boolean;
    error: string | undefined;
    lastUpdated: string | null;
}

const initialState = {
    entities: [],
    entity: null,
    loading: false,
    error: undefined,
    lastUpdated: null,
};

export interface BaseService<T extends { id: string | number }> {
    getAll: (params?: Record<string, any>) => Promise<T[]>;
    getById: (id: string | number) => Promise<T>;
    create: (data: any) => Promise<T>;
    update: (id: string | number, data: Partial<any>) => Promise<T>;
    delete: (id: string | number) => Promise<void>;
}

export const createBaseSlice = <T extends { id: string | number }>(
    name: string,   
    service: BaseService<T>,
    extraReducers?: any
) => {
    // Async Thunks
    const fetchAll = createAsyncThunk(
        `${name}/fetchAll`,
        async (params?: Record<string, any>) => {
            return await service.getAll(params);
        }
    );

    const fetchOne = createAsyncThunk(
        `${name}/fetchOne`,
        async (id: string | number) => {
            return await service.getById(id);
        }
    );

    const createEntity = createAsyncThunk(
        `${name}/create`,
        async (data: Partial<T>) => {
            return await service.create(data);
        }
    );

    const updateEntity = createAsyncThunk(
        `${name}/update`,
        async ({ id, data }: { id: string | number; data: Partial<T> }) => {
            return await service.update(id, data);
        }
    );

    const deleteEntity = createAsyncThunk(
        `${name}/delete`,
        async (id: string | number) => {
            await service.delete(id);
            return id;
        }
    );

    // Slice
    const slice = createSlice({
        name,
        initialState: initialState as BaseState<T>,
        reducers: {
            clearEntity: (state: Draft<BaseState<T>>) => {
                state.entity = null;
            },  
            clearEntities: (state: Draft<BaseState<T>>) => {
                state.entities = [];
            },
            clearError: (state: Draft<BaseState<T>>) => {
                state.error = undefined;
            },
            selectEntity: (state: Draft<BaseState<T>>, action: PayloadAction<T | {id: string | number} | null>) => {
                state.entity = state.entities.find((ent) => ent.id === action.payload?.id) || null;
            },
            createEmptyEntity: (state: Draft<BaseState<T>>, action: PayloadAction<Partial<T>>) => {
                state.entity = action?.payload as Draft<T> || {} as Draft<T>;
            },
            updateEntityState: (state: Draft<BaseState<T>>, action: PayloadAction<T>) => {
                state.entity = action?.payload as Draft<T> || {} as Draft<T>;
            },
        },
        extraReducers: (builder) => {
            // Fetch All
            builder.addCase(fetchAll.pending, (state: Draft<BaseState<T>>) => {
                state.loading = true;
                state.error = undefined;
            });
            builder.addCase(fetchAll.fulfilled, (state: Draft<BaseState<T>>, action: PayloadAction<T[]>) => {
                state.loading = false;
                state.entities = action.payload as Draft<T>[];
                state.lastUpdated = new Date().toISOString();
            });
            builder.addCase(fetchAll.rejected, (state: Draft<BaseState<T>>, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch entities';
                addNotificationFx({
                    type: 'danger',
                    message: action.error.message || 'Failed to fetch entities',
                });
            });

            // Fetch One
            builder.addCase(fetchOne.pending, (state: Draft<BaseState<T>>) => {
                state.loading = true;
                state.error = undefined;
            });
            builder.addCase(fetchOne.fulfilled, (state: Draft<BaseState<T>>, action: PayloadAction<T>) => {
                state.loading = false;
                state.entity = action.payload as Draft<T>;
            });
            builder.addCase(fetchOne.rejected, (state: Draft<BaseState<T>>, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch entity';
                addNotificationFx({
                    type: 'danger',
                    message: action.error.message || 'Failed to fetch entity',
                });
            });

            // Create
            builder.addCase(createEntity.pending, (state: Draft<BaseState<T>>) => {
                state.loading = true;
                state.error = undefined;
            });
            builder.addCase(createEntity.fulfilled, (state: Draft<BaseState<T>>, action: PayloadAction<T>) => {
                state.loading = false;
                state.entities.push(action.payload as Draft<T>);
                state.entity = action.payload as Draft<T>;
                state.lastUpdated = new Date().toISOString();
            });
            builder.addCase(createEntity.rejected, (state: Draft<BaseState<T>>, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to create entity';
                addNotificationFx({
                    type: 'danger',
                    message: action.error.message || 'Failed to fetch entity',
                });
            });

            // Update
            builder.addCase(updateEntity.pending, (state: Draft<BaseState<T>>) => {
                state.loading = true;
                state.error = undefined;
            });
            builder.addCase(updateEntity.fulfilled, (state: Draft<BaseState<T>>, action: PayloadAction<T>) => {
                state.loading = false;
                const updatedEntity = action.payload as Draft<T>;
                state.entities = state.entities.map((ent) =>
                    ent.id === updatedEntity.id ? updatedEntity : ent
                );
                state.entity = updatedEntity;
                state.lastUpdated = new Date().toISOString();
            });
            builder.addCase(updateEntity.rejected, (state: Draft<BaseState<T>>, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update entity';
                addNotificationFx({
                    type: 'danger',
                    message: action.error.message || 'Failed to update entity',
                });
            });

            // Delete
            builder.addCase(deleteEntity.pending, (state: Draft<BaseState<T>>) => {
                state.loading = true;
                state.error = undefined;
            });
            builder.addCase(deleteEntity.fulfilled, (state: Draft<BaseState<T>>, action: PayloadAction<string|number>) => {
                state.loading = false;
                state.entities = state.entities.filter((ent) => ent.id !== action.payload);
                state.entity = null;
                state.lastUpdated = new Date().toISOString();
            });
            builder.addCase(deleteEntity.rejected, (state: Draft<BaseState<T>>, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to delete entity';
                addNotificationFx({
                    type: 'danger',
                    message: action.error.message || 'Failed to delete entity',
                });
            });

            extraReducers && extraReducers(builder);
        },
    });

    return {
        slice,
        thunks: {
            fetchAll,
            fetchOne,
            createEntity,
            updateEntity,
            deleteEntity,
        },
    };
};
