const ns = "partnerDocument";

const acitons = {
    create: "Создать документ",
    edit: "Редактировать документ",
    view: "Просмотреть документ",
};

// namespace title
const title_ns = "Документы";

const title = "Документ";

export const PartnerDocumentConstants = {
    ns,
    title_ns,
    title,
    acitons,
};