import { useSearchParams } from "react-router-dom";
import { Processing } from "../model/types";
import { Typography } from "@mui/joy";

export const ProcessingCard = ( props: {data: Processing} ) => {
    const [ , setSearchParams ] = useSearchParams();
    return (
        <div
            key={props.data.id}
            className="p-2 rounded shadow-sm border-gray-100 border-2"
        >
        <h3
          className="text-md mb-3 text-gray-900 hover:text-cyan-700"
        >
            {props.data.name}
        </h3>
        <div className="flex flex-row items-center mt-2 text-sm gap-2">
          {/* {data.stacks.map((stack) => (
            <Badge key={stack.id} color="green">
              {stack.name}
            </Badge>
          ))} */}
        </div>
        <div className="flex flex-col items-start mt-2">
            <Typography onClick={() => setSearchParams({view: 'partner', id: props.data.recruitment?.employee.id.toString()})} level="body-sm" className="text-sm text-gray-700 hover:text-cyan-700">Кандидат: {props.data.recruitment?.employee.name}</Typography>
            <Typography onClick={() => setSearchParams({view: 'vacancy', id: props.data.vacancy.id.toString()})} level="body-sm" className="text-sm text-gray-700 hover:text-cyan-700">Вакансия: {props.data.vacancy.title}</Typography>
        </div>
        <div className="flex flex-row items-center mt-2 text-sm gap-2">
          <Typography level="body-sm" className="text-sm text-gray-700">Дата создания: {new Date(props.data.created_at).toLocaleDateString('ru-RU')}</Typography>
        </div>
      </div>
    )
};