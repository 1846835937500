import { BaseService } from '../../../shared/api/base';
import { Stack } from '../model/types';

class StackService extends BaseService<Stack, Stack> {
    constructor() {
        super('/stacks');
    }

    async getByType(type: string): Promise<Stack[]> {
        return this.client.get(`${this.baseUrl}`, { params: { type } });
    }
}

export const stackService = new StackService();
