import { store } from "@/app/providers/StoreProvider";
import { Provider } from "react-redux";

export interface StoreProviderProps {
    children: React.ReactNode;
}

export const StoreProvider = (props: StoreProviderProps) => {
    const { children } = props;
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};