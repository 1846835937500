import { FC, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { handleCallbackFx } from '../model/store';

const AuthCallback: FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const isProcessing = useRef(false);

    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        
        if (!code || !state) {
            console.error('Missing code or state');
            navigate('/', { replace: true });
            return;
        }

        if (isProcessing.current) {
            return;
        }

        isProcessing.current = true;
        handleCallbackFx(searchParams)
            .then(() => {
                sessionStorage.removeItem('oidcState');
                const initialPage = sessionStorage.getItem('initialPage');
                if (initialPage) {
                    navigate(initialPage, { replace: true });
                    sessionStorage.removeItem('initialPage');
                } else {
                    navigate('/', { replace: true });
                }
            })
            .catch((error) => {
                console.error('Auth callback failed:', error);
                navigate('/', { replace: true });
            })
            .finally(() => {
                isProcessing.current = false;
            });
    }, [searchParams, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-center">
                <h2 className="text-xl font-semibold mb-2">Проводим аутентификацию...</h2>
                <p className="text-gray-600">Пожалуйста, подождите, пока процесс завершится.</p>
            </div>
        </div>
    );
};

export default AuthCallback;
