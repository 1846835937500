import { createEvent, createStore } from 'effector';
import { PreparedFilter } from './types';

// Events
export const setFilter = createEvent<PreparedFilter[]>();
export const addFilter = createEvent<PreparedFilter>();
export const updateFilter = createEvent<PreparedFilter>();
export const removeFilter = createEvent<string>(); // column name
export const clearFilters = createEvent();

// Store
export const $filters = createStore<PreparedFilter[]>([])
    .on(setFilter, (_, filters) => filters)
    .on(updateFilter, (state, filter) => {
        const existingFilterIndex = state.findIndex(f => f.column === filter.column);
        if (existingFilterIndex !== -1) {
            return state.map((f, index) => 
                index === existingFilterIndex ? filter : f
            );
        }
        return [...state, filter];
    })
    .on(addFilter, (state, filter) => {
        const existingFilterIndex = state.findIndex(f => f.column === filter.column);
        if (existingFilterIndex !== -1) {
            return state.map((f, index) => 
                index === existingFilterIndex ? filter : f
            );
        }
        return [...state, filter];
    })
    .on(removeFilter, (state, column) => 
        state.filter(f => f.column !== column)
    )
    .on(clearFilters, () => []);