const ns = "partnerType";
const relation = "partner_type";

const acitons = {
    create: "Создать тип контрагента",
    edit: "Редактировать тип контрагента",
};

// namespace title
const title_ns = "Типы контрагентов";

const title = "Тип контрагента";

export const PartnerTypeConstants = {
    ns,
    title_ns,
    relation,
    title,
    acitons,
};