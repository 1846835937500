import { Partner, PartnerConstants, partnersActions } from "@/entities/partner";
import { RateType, rateTypesActions } from "@/entities/rate-type";
import { Stack, stacksActions } from "@/entities/stack";
import { vacanciesActions, Vacancy, VacancyConstants } from "@/entities/vacancy";
import { ViewProcessings } from "@/features/processing";
import { VacancyHeadActions, VacancyHeadTitle } from "@/features/vacancy";
import { VacancyView } from "@/features/vacancy/editVacancy/ui/view";
import { useAppDispatch, useAppSelector, useEntityLoader } from "@/shared/lib/hooks";
import { DrawerWidget } from "@/widgets/layouts/drawer/ui/Drawer";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const VacancyDrawer = () => {
    const [ searchParams ] = useSearchParams();
    const dispatch = useAppDispatch();

    const [vacanciesState, ] = useEntityLoader<[Vacancy, Partner, RateType, Stack]>([
        {
            state: useAppSelector(state => state.vacancies),
            action: vacanciesActions,
            config: { entityName: VacancyConstants.title }
        },
        {
            state: useAppSelector(state => state.partners),
            action: partnersActions,
            config: { entityName: PartnerConstants.title }
        },
        {
            state: useAppSelector(state => state.rateTypes),
            action: rateTypesActions,
            config: { entityName: "Виды ставок" }
        },
        {
            state: useAppSelector(state => state.stacks),
            action: stacksActions,
            config: { entityName: "Стеки" }
        },
    ], 'VacancyView');

    useEffect(() => {
        if (searchParams.get('view') === 'vacancy' && searchParams.get('id')) {
            if (searchParams.get('id') === 'new') {
                dispatch(vacanciesActions.createEmptyEntity({} as Vacancy));
            } else {
                if (!vacanciesState.entity || vacanciesState.entity.id !== Number(searchParams.get('id')))
                    dispatch(vacanciesActions.selectEntity({id: Number(searchParams.get('id'))}));
                // dispatch(vacanciesActions.fetchOne(Number(searchParams.get('id'))));    
            }
            
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, vacanciesState.loading])

    if (searchParams.get('view') === 'vacancy' && searchParams.get('id')) {
        return (
            vacanciesState.entity &&
            <DrawerWidget 
                isOpen={searchParams.get('view') === 'vacancy' && searchParams.get('id') ? true : false}
                title={<VacancyHeadTitle blocked={false} />}
                topRightHeader={<VacancyHeadActions />}
                actions={{
                    clearEntity: vacanciesActions.clearEntity,
                }}
                childs={[
                    <VacancyView key={Number(searchParams.get('id'))} id={Number(searchParams.get('id'))} editable={true} />,
                    <ViewProcessings key={'proc_' + searchParams.get('id')} vacancy={vacanciesState.entity as Vacancy} />
                ]}
            />
        )
    }

    return null
};