import { createBaseSlice } from '@/shared/lib/redux-base/createBaseSlice';
import { UsersService } from '../api/usersService';
import { User } from './types';

const usersService = new UsersService();

const { slice, thunks } = createBaseSlice<User>('users', usersService);

export const usersReducer = slice.reducer;
export const usersActions = { ...slice.actions, ...thunks };
