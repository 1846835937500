import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Divider, GlobalStyles, IconButton, List, ListItem, ListItemButton, ListItemContent, Sheet, Typography } from '@mui/joy';
import { HiBriefcase, HiChevronDown, HiCog, HiHome, HiUserGroup, HiArrowCircleRight, HiChevronUp } from 'react-icons/hi';
import { useUnit } from 'effector-react';
import { $authStore, logoutFx } from '@/processes/auth';
import { PartnerConstants } from '@/entities/partner';
import { StatusConstants } from '@/entities/status';
import { PartnerTypeConstants } from '@/entities/partner-type';
import { VacancyConstants } from '@/entities/vacancy';
import { RecruitmentConstants } from '@/entities/recruitment';
import { ProcessingConstants } from '@/entities/processing';
import { closeSidebar } from '../../layout/lib/toggler';

interface ToggleProps {
    defaultExpanded?: boolean;
    renderToggle: (open: boolean, setOpen: (open: boolean) => void) => React.ReactNode;
    children: React.ReactNode;
}

const Toggler: React.FC<ToggleProps> = ({
    defaultExpanded = false,
    renderToggle,
    children,
}) => {
    const [open, setOpen] = useState(defaultExpanded);
    
    useEffect(() => {
        setOpen(defaultExpanded);
    }, [defaultExpanded]);

    return (
        <>
            {renderToggle(open, setOpen)}
            <Box
                sx={[
                    {
                        display: open ? 'grid': 'none',
                    }
                ]}
            >
                {children}
            </Box>
        </>
    );
};

export const Sidebar: React.FC = () => {
    const [ , setSearchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const auth = useUnit($authStore);
    
    const routes = [
        { path: '/', icon: HiHome, label: 'Сводка' },
        { path: '/partners', icon: HiUserGroup, label: PartnerConstants.title_ns },
        { path: '/vacancies', icon: HiBriefcase, label: VacancyConstants.title_ns },
        { path: '/recruitments', icon: HiUserGroup, label: RecruitmentConstants.title_ns },
        { path: '/processings', icon: HiCog, label: ProcessingConstants.title_ns },
        { path: '/settings', icon: HiCog, label: 'Настройки', children: [
            { action: () => { setSearchParams({ view: 'statuses' }); navigate('/settings') }, path: '/settings?view=statuses', label: StatusConstants.title_ns },
            { action: () => { setSearchParams({ view: 'partner-types' }); navigate('/settings') }, path: '/settings?view=partner-types', label: PartnerTypeConstants.title_ns },
        ]},
    ];

    const handleLogout = () => {
        logoutFx();
    };

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100vh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >            
            <Box
                className="Sidebar-overlay"
                onClick={() => closeSidebar()}
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
            />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography level="title-lg">Aiotex</Typography>
            </Box>
            
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${ListItemButton}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    {routes.map((route) => (
                        route.children ? (
                            <Toggler
                                key={route.path}
                                defaultExpanded={pathname.includes(route.path)}
                                renderToggle={(open, setOpen) => (
                                    <ListItemButton onClick={() => setOpen(!open)}>
                                    {<route.icon />}
                                    <ListItemContent>
                                        <Typography level="title-sm">{route.label}</Typography>
                                    </ListItemContent>
                                    {open ? <HiChevronDown/> : <HiChevronUp/>}
                                    </ListItemButton>) }
                            >
                                {route.children.map((childRoute) => (
                                    <ListItem key={childRoute.path}>
                                        <ListItemButton
                                            key={childRoute.path}
                                            className='ml-2'
                                            component={NavLink}
                                            to={childRoute.path}
                                            selected={pathname + search === childRoute.path}
                                            onClick={() => childRoute.action && childRoute.action()}
                                        >
                                            <Typography level="body-xs">{childRoute.label}</Typography>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </Toggler>
                        ) : 
                        <ListItem key={route.path}>
                            <ListItemButton
                                component={NavLink}
                                to={route.path}
                                selected={pathname + search === route.path}
                            >
                                <route.icon />
                                <Typography level="title-sm">{route.label}</Typography>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Divider />
            
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">{auth.user?.username}</Typography>
                    <Typography level="body-xs">{auth.user?.email}</Typography>
                </Box>
                <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={handleLogout}
                >
                    <HiArrowCircleRight />
                </IconButton>
            </Box>
        </Sheet>
    );
};
