import { PartnerDrawer } from "@/pages/partners/ui/PartnerDrawer"
import { RecruitmentDrawer } from "@/pages/recruitments"
import { VacancyDrawer } from "@/pages/vacancies/ui/VacancyDrawer"
import { ProtectedRoute } from "@/processes/auth"
import React, { PropsWithChildren } from "react"

export const SearchProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <ProtectedRoute>
            <PartnerDrawer />
            <VacancyDrawer />
            <RecruitmentDrawer />
            {children}
        </ProtectedRoute>
    )
}