import { Badge } from "flowbite-react";
import { Vacancy } from "../model/types";
import { useSearchParams } from "react-router-dom";

interface VacancyCardProps {
    data: Vacancy;
}

export const VacancyCard = (props: VacancyCardProps) => {
    const [ , setSearchParams ] = useSearchParams();
    return (
      <div
        key={props.data.id}
        className="p-2 rounded shadow-sm border-gray-100 border-2"
      >
        <h3
          className="text-md mb-3 text-gray-900 hover:text-cyan-700"
          onClick={() => setSearchParams({ view: 'vacancy', id: props.data.id.toString() })}
        >
          {props.data.title}
        </h3>
        <div className="flex flex-row items-center mt-2 text-sm gap-2">
          {props.data.stacks?.map((stack) => (
            <Badge key={stack.id} color="green">
              {stack.name}
            </Badge>
          ))}
        </div>
        <div className="flex flex-col items-start mt-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-700 hover:text-cyan-700"
            href={`/partners/${props.data.partner?.id}`}
          >
            Партнер: {props.data.partner?.name}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-700 hover:text-cyan-700"
            href={`/partners/${props.data.client?.id}`}
          >
            Клиент: {props.data.client?.name}
          </a>
        </div>
  
        <p className="text-sm text-cyan-700">
          Часовой рейт:{" "}
          {(props.data.max_salary_rate || 0) * (props.data.rate_type?.to_hour_modificator || 0)}
        </p>
      </div>
    );
  };