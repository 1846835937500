import { Partner, PartnerConstants, partnersActions } from "@/entities/partner";
import { PartnerReference } from "@/entities/partner/ui/PartnerReference";
import { Status } from "@/entities/status";
import { StatusReference } from "@/entities/status/ui/StatusReference";
import { Recruitment, RecruitmentConstants, recruitmentsActions } from "@/entities/recruitment";
import { EditableTextField, LabelComponent } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { Box, IconButton, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import { PartnerType } from "@/entities/partner-type";

interface RecruitmentViewProps {
    id: number;
    editable?: boolean;
}

export const RecruitmentView = ({ id, editable }: RecruitmentViewProps) => {
    const dispatch = useAppDispatch();

    const [showDetails, setShowDetails] = useState(true);
    const [emplCreated, setEmplCreated] = useState(false);

    const recruitmentsState = useAppSelector(state => state.recruitments);
    const partnersState = useAppSelector(state => state.partners);
    const statusesState = useAppSelector(state => state.statuses);
    const partnerTypesState = useAppSelector(state => state.partnerTypes);

    useEffect(() => {
        if (id) {
            dispatch(recruitmentsActions.selectEntity({ id }));
        }
    }, [id, recruitmentsState.entities, dispatch]);
    // 
    useEffect(() => {
        if (emplCreated && partnersState.entities) {
            dispatch(recruitmentsActions.updateEntityState({
                ...recruitmentsState.entity,
                employee: partnersState.entity
            } as Recruitment));

            if (window.confirm("Открыть редактор кандидата?")) {
                window.open(`/partners?view=partner&id=${partnersState.entity?.id}`, '_blank');
            }
            setEmplCreated(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emplCreated]);

    function handleSelectRelated(query: Partner | Status, field: string) {
        dispatch(recruitmentsActions.updateEntityState({
            ...recruitmentsState.entity,
            [field]: query
        } as Recruitment));

        if (id) {
            dispatch(recruitmentsActions.updateEntity({
                id,
                data: { [field]: query.id }
            }));
        }
    }

    function handleUpdateRecruitment(query: string | number, field: string) {
        dispatch(recruitmentsActions.updateEntityState({
            ...recruitmentsState.entity,
            [field]: query
        } as Recruitment));

        if (id) {
            dispatch(recruitmentsActions.updateEntity({
                id,
                data: { [field]: query }
            }));
        }
    }

    const handleCreateEmployee = async (name: string) => {
        const action = await dispatch(partnersActions.createEntity({
          name: name,
          status: statusesState.entities.find(status => status.relation === PartnerConstants.relation && status.weight === 0),
          types: partnerTypesState.entities.filter((type: PartnerType) => type.name === "Кандидат"),
        }))
        console.log(action);
        return action.payload as Partner
    };

    return (
        recruitmentsState.entity && (
            <List>
                <ListItem>
                    <ListItemContent>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                            }}
                        >
                            <IconButton
                                variant="plain"
                                color="neutral"
                                size="sm"
                                onClick={() => setShowDetails(!showDetails)}
                            >
                                {showDetails ? <HiChevronDown /> : <HiChevronRight />}
                            </IconButton>
                            <Typography level="body-lg">
                                Основная информация
                            </Typography>
                        </Box>
                    </ListItemContent>
                </ListItem>

                {showDetails && (
                    <Box sx={{ pl: 4 }}>
                        <ListItem>
                            <ListItemContent>
                                <StatusReference
                                    status={recruitmentsState.entity.status || {} as Status}
                                    statuses={statusesState.entities.filter(status => status.relation === RecruitmentConstants.relation)}
                                    onApply={(status) => handleSelectRelated(status, 'status')}
                                    labelComponent={<LabelComponent>Статус</LabelComponent>}
                                    blocked={!editable}
                                />
                            </ListItemContent>
                        </ListItem>

                        <ListItem>
                            <ListItemContent>
                                <PartnerReference
                                    partner={recruitmentsState.entity.employee}
                                    onApply={(partner) => handleSelectRelated(partner, 'employee')}
                                    labelComponent={<LabelComponent>Кандидат</LabelComponent>}
                                    blocked={!editable || emplCreated}
                                    onCreate={handleCreateEmployee}
                                    onSearch={(query: string) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                resolve(partnersState.entities.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                            }, 1000);
                                        });
                                    }}
                                />
                            </ListItemContent>
                        </ListItem>

                        <ListItem>
                            <ListItemContent>
                                <PartnerReference
                                    partner={recruitmentsState.entity.recruiter}
                                    onApply={(partner) => handleSelectRelated(partner, 'recruiter')}
                                    labelComponent={<LabelComponent>Рекрутер</LabelComponent>}
                                    blocked={!editable}
                                    onSearch={(query: string) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                resolve(partnersState.entities.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                            }, 1000);
                                        });
                                    }}
                                />
                            </ListItemContent>
                        </ListItem>

                        <ListItem>
                            <ListItemContent>
                                <Typography level="body-sm">Комментарий</Typography>
                                <EditableTextField
                                    value={recruitmentsState.entity?.comment}
                                    onApply={(value: string | number) => handleUpdateRecruitment(value, 'comment')}
                                    blocked={!editable}
                                />
                            </ListItemContent>
                        </ListItem>
                    </Box>
                )}
            </List>
        )
    );
};
