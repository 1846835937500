import { TableAdmin, Column } from "@/shared";

interface TableProps<T extends { id: number }> {
    objects: T[];
    columns: Column[];
    orderableColumns?: string[];
    onClick?: (value: T) => void;
    onDelete?: (value: T) => void;
    onEdit?: (value: T) => void;    
    renderCard?: (value: T) => React.ReactNode
}

export function TableWidget<T extends { id: number }>({ objects, columns, orderableColumns, onClick, onDelete, onEdit, renderCard }: TableProps<T>) {

    return (
        <TableAdmin
                data={objects}
                columns={columns}
                onClick={(id: string | number) => onClick && onClick(objects.find(object => object.id === parseInt(id as string)) || {} as T)}
                {...onDelete && {onDelete: (id: string |number) => onDelete && onDelete(objects.find(object => object.id === parseInt(id as string)) || {} as T)}}
                {...onEdit && {onEdit: (id: string |number) => onEdit && onEdit(objects.find(object => object.id === parseInt(id as string)) || {} as T)}}
                orderableColumns={orderableColumns}
                renderCard={renderCard}
        />
    )
};