import { EditableBadgeField } from "@/shared";
import { Status } from "../model/types";

interface StatusReferenceProps {
    status: Status; 
    statuses: Status[];
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (value: Status, key: string) => void;
};

export const StatusReference = ({status, statuses, blocked, labelComponent, onApply}: StatusReferenceProps) => {
    return (
        <EditableBadgeField 
            value={status?.id}
            badges={statuses}
            blocked={blocked}
            labelComponent={labelComponent}
            onApply={(id: string | number) => onApply(statuses.find((s) => s.id === id) || {} as Status, 'status')}
        />
    )
};