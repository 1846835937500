import { EditableBadgeField } from "@/shared";
import { RateType } from "../model/types";
import { useAppSelector } from "@/shared/lib/hooks";

interface RateTypeReferenceProps {
    rateType: RateType | undefined; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (values: RateType) => void;
};

export const RateTypeReference = ({rateType, blocked, labelComponent, onApply}: RateTypeReferenceProps) => {
    const rateTypes = useAppSelector(state => state.rateTypes.entities);
    return (
        <EditableBadgeField 
            value={rateType?.id}
            badges={
                rateTypes.map((entity) => ({
                    id: entity.id,
                    name: entity.name,
                    color: 'info',
                }))
            }
            blocked={blocked}
            labelComponent={labelComponent}
            onApply={(values: string | number) => onApply(rateTypes.find((s) => s.id === values) || {} as RateType)}
        />
    )
};