import { Loader } from "@/shared/ui/loader/Loader";
import { AdminPageHeader } from "../header/AdminPageHeader"
import { HiHome } from "react-icons/hi"

interface AdminPageProps {
    title: string;
    children: React.ReactNode;
    buttonsBlock?: React.ReactNode;
    loading: boolean;
}

export const AdminPage = ( { title, children, buttonsBlock, loading }: AdminPageProps) => {
 return (
  <div>
    <AdminPageHeader 
        title={title}
        breadcrumbs={[
            {text: "Админка", href: "/", icon: HiHome},
        ]}>
        {buttonsBlock}
    </AdminPageHeader>
    <div className="mt-4 h-full">
        {loading ? <Loader /> : children}
    </div>
  </div>
 )
}