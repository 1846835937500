import { BaseService } from '@/shared/api/base';
import { CreateRequisiteRequest, Requisite as PartnerRequisite} from '../model/types';

class PartnerRequisiteService extends BaseService<PartnerRequisite, CreateRequisiteRequest> {
    constructor() {
        super('/partners/requisites');
    }
}

export const partnerRequisiteService = new PartnerRequisiteService();
