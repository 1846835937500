import { createEvent, createStore } from 'effector';
import { Notification, NotificationState } from './types';

const initialState: NotificationState = {
    notifications: [],
};

export const addNotificationFx = createEvent<Omit<Notification, 'id'>>();
export const removeNotificationFx = createEvent<string>();

export const $notifications = createStore<Notification[]>(initialState.notifications)
    .on(addNotificationFx, (state, notification) => {
        const id = Math.random().toString(36).substring(7);
        const newNotification = {
            ...notification,
            id,
            duration: notification.duration || 15000,
        };
        return [...state, newNotification];
    })
    .on(removeNotificationFx, (state, id) => 
        state.filter((notification) => notification.id !== id)
    );

// Автоматическое удаление уведомлений
$notifications.watch((notifications) => {
    notifications.forEach((notification) => {
        if (!notification.duration) return;
        
        setTimeout(() => {
            removeNotificationFx(notification.id);
        }, notification.duration);
    });
});
