import { FC, FormEvent, useRef, useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import { Button, IconButton, Switch } from '@mui/joy';
import { CreateDocumentRequest, UploadDocumentRequest } from '../model/types';
import { HiCheckCircle, HiDocumentDownload, HiX } from 'react-icons/hi';

export interface DocumentFormProps {
    onSubmit: (data: CreateDocumentRequest | UploadDocumentRequest) => Promise<void>;
    onDecline?: () => void;
    initialData: CreateDocumentRequest | UploadDocumentRequest;
    isLoading?: boolean;
}

export const DocumentForm: FC<DocumentFormProps> = ({
    onSubmit,
    initialData,
    isLoading,
    onDecline,
}) => {
    const [ switchFile, setSwitchFile ] = useState(false);
    const [ , setFileList ] = useState<FileList | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        
        await onSubmit({
            name: formData.get('name') as string,
            partner_id: initialData.partner_id,
            ...(switchFile ? {file_path: formData.get('file_path') as File}: {url: formData.get('url') as string}),
        });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Имя реквизита" />
                </div>
                <TextInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Имя реквизита"
                    required
                    disabled={isLoading}
                    defaultValue={initialData?.name}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="switchFile" value="Загрузить файл / указать ссылку" />
                </div>
                <Switch
                    id="switchFile"
                    checked={switchFile}
                    onChange={(event) => setSwitchFile(event.target.checked)}
                />
            </div> 
            {switchFile ? 
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="file_path" value="Файл" />
                    </div>
                    <Button
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        variant="outlined"
                        color="neutral"
                        startDecorator={
                            fileInputRef.current?.files?.length ?
                            <HiCheckCircle/>
                            : <HiDocumentDownload/>
                        }>
                        Файл
                        <input 
                            ref={fileInputRef}
                            onChange={(event) => setFileList(event.target.files)}
                            value={undefined}
                            style={{
                                clip: "rect(0 0 0 0)",
                                clipPath: "inset(50%)",
                                height: "1px",
                                overflow: "hidden",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                whiteSpace: "nowrap",
                                width: "1px"}}
                            id="file_path"
                            name="file_path"
                            type="file" />
                    </Button>
                    {fileInputRef.current?.value && fileInputRef.current?.files?.length && 
                        <div>
                            <Label htmlFor="file_path" value={fileInputRef.current?.files[0]?.name} />
                            <IconButton
                                size="sm"
                                variant="plain"
                                onClick={() => {setFileList(null); fileInputRef.current && (fileInputRef.current.value = '')}}
                                color="neutral"
                            >
                                <HiX />
                            </IconButton>
                        </div>
                    }
                </div>:
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="url" value="Ссылка" />
                    </div>
                    <TextInput
                        id="url"
                        name="url"
                        type="text"
                        placeholder="Ссылка"
                        disabled={isLoading}
                        defaultValue={(initialData as CreateDocumentRequest).url}
                    />
                </div>
            }    
            <div className="col-span-2 flex justify-end gap-2">
                <Button
                    type="button"
                    onClick={onDecline}
                    disabled={isLoading}
                    color="neutral"
                >
                    Отмена
                </Button>
                
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
