import { TableAdmin } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { partnerRequisitesActions } from "../model/slice";
import { Requisite } from "../model/types";

interface RequisitesTableProps {
    partnerId: number,
    onEditModalAction: (requisite: Requisite) => void,
    editable?: boolean,
}

export const RequisitesTable = ({ partnerId, onEditModalAction, editable }: RequisitesTableProps) => {
    const dispatch = useAppDispatch();

    const partnerRequisitesState = useAppSelector(state => state.partnerRequisites);

    return (
        <TableAdmin 
            data={partnerRequisitesState.entities.filter(c => c.partner_id === partnerId)}
            columns={[
                {name: 'name', alias: 'Имя'},
                {name: 'value', alias: 'Значение'},
            ]}
            {...editable && {onEdit:(id: string | number) => onEditModalAction(partnerRequisitesState.entities.find(c => c.id === id) as Requisite)}}
            {...editable && {onDelete: (id: string |number) => dispatch(partnerRequisitesActions.deleteEntity(id as number))}}
        />
    )
};