import { Partner, partnersActions } from "@/entities/partner";
import { Contact, PartnerContactConstants, partnerContactsActions } from "@/entities/partner-contact";
import { ContactForm } from "@/entities/partner-contact/ui/ContactForm";
import { ContactTable } from "@/entities/partner-contact/ui/ContactsTable";
import { CreateDocumentRequest, PartnerDocumentConstants, partnerDocumentsActions, UploadDocumentRequest } from "@/entities/partner-document";
import { DocumentForm } from "@/entities/partner-document/ui/DocumentForm";
import { DocumentsTable } from "@/entities/partner-document/ui/DocumentsTable";
import { Requisite, PartnerRequisiteConstants, partnerRequisitesActions } from "@/entities/partner-requisite";
import { RequisiteForm } from "@/entities/partner-requisite/ui/RequisiteForm";
import { RequisitesTable } from "@/entities/partner-requisite/ui/RequisitesTable";
import { PartnerType, PartnerTypeConstants, PartnerTypeReference } from "@/entities/partner-type";
import { PartnerListReference, PartnerReference } from "@/entities/partner/ui/PartnerReference";
import { User, usersActions } from "@/entities/user";
import { $authStore } from "@/processes/auth";
import { EditableLinkField, LabelComponent } from "@/shared";
import { useAppDispatch, useAppSelector, useEntityLoader } from "@/shared/lib/hooks";
import { ModalWidget } from "@/widgets/layouts/modal/ui/Modal";
import { Box, Checkbox, IconButton, List, ListItem, ListItemContent, Tooltip, Typography } from "@mui/joy";
import { useUnit } from "effector-react";
import { useEffect, useState } from "react";
import { HiLink, HiPlus, HiUserCircle } from "react-icons/hi";
 
interface PartnerViewProps {
    id: number;
    editable?: boolean;
}

export const PartnerView = ({ id, editable }: PartnerViewProps) => {
    const { user } = useUnit($authStore);
    const dispatch = useAppDispatch();
    const [ contactModalOpen, setContactModalOpen ] = useState(false);
    const [ requisiteModalOpen, setRequisiteModalOpen ] = useState(false);
    const [ documentModalOpen, setDocumentModalOpen ] = useState(false);

    const partnersState = useAppSelector(state => state.partners);
    const partnerContactsState = useAppSelector(state => state.partnerContacts);
    const partnerRequisitesState = useAppSelector(state => state.partnerRequisites);
    const partnerDocumentsState = useAppSelector(state => state.partnerDocuments);
    const partnerTypesState = useAppSelector(state => state.partnerTypes);

    const [ usersState ] = useEntityLoader<[User]>([
        {
            state: useAppSelector(state => state.users),
            action: usersActions,
            config: { entityName: "Пользователи" }    
        }
    ], 'PartnerView');

    useEffect(() => {
        if (id) {
            dispatch(partnersActions.selectEntity({id: id}));
        }
    }, [id, partnersState.entities, dispatch]);

    return (
    partnersState.entity &&
        <List>
            <ListItem>
                <ListItemContent>
                    <PartnerTypeReference
                        onApply={(values: PartnerType[]) => {
                            dispatch(partnersActions.updateEntity({id: id, data: {types: values}}));
                        }}
                        onSearch={(query: string) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve(partnerTypesState.entities.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                }, 1000);
                            });
                        }}
                        pts={partnersState.entity?.types || []}
                        blocked={!editable}
                        labelComponent={
                            <LabelComponent>{PartnerTypeConstants.title}</LabelComponent>
                        }
                    />
                </ListItemContent>
            </ListItem>
            <ListItem>
                <ListItemContent>
                    <Box className="w-full grid grid-cols-5 items-baseline">
                        <Box className="col-span-2 md:col-span-1">
                            <LabelComponent>Является группой</LabelComponent>
                        </Box>
                        <Box className="col-span-3 md:col-span-4 px-4">
                            <Checkbox disabled={!editable} checked={partnersState.entity.is_group} onChange={(v) => {
                                dispatch(partnersActions.updateEntity({id: id, data: {is_group: v.target.checked}}))
                                if (!v.target.checked) {
                                    partnersState.entities.forEach((entity) => {
                                        if (entity.parent_id === id) {
                                            dispatch(partnersActions.updateEntity({id: entity.id, data: {parent_id: null}}));
                                        }
                                    });
                                }
                            }} />
                        </Box>
                    </Box>
                </ListItemContent>
            </ListItem>
            {partnersState.entity.is_group && <ListItem>
                <ListItemContent>
                    <PartnerListReference 
                        partners={partnersState.entities.filter(p => p.parent_id === id)}
                        onApply={(values: Partner[]) => {
                            values.forEach((value) => {
                                dispatch(partnersActions.updateEntity({id: value.id, data: {parent_id: id}}));
                            });
                        }}
                        blocked={!editable}
                        onSearch={(query: string) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve(partnersState.entities.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase()) && entity.parent_id !== id && entity.id !== id));
                                }, 1000);
                            });
                        }}
                        labelComponent={
                            <LabelComponent>Члены группы</LabelComponent>
                        }
                    />
                </ListItemContent>
            </ListItem>}
            {partnersState.entity.parent_id && <ListItem>
                <ListItemContent>
                    <PartnerReference 
                        partner={partnersState.entities.find(p => p.id === partnersState.entity?.parent_id)}
                        blocked={!editable}
                        labelComponent={
                            <LabelComponent>Родитель <IconButton size="sm" onClick={() => {window.location.href = `/partners?view=partner&id=${partnersState.entity?.parent_id}`}}><HiLink /></IconButton></LabelComponent>
                        }
                        onApply={(value: Partner) => {
                            dispatch(partnersActions.updateEntity({id: id, data: {parent_id: value.id}}));}
                        }
                        onSearch={(query: string) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve(partnersState.entities.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase()) && entity.parent_id !== id && entity.id !== id));
                                }, 1000);
                            });
                        }}
                    />
                </ListItemContent>
            </ListItem>}
            {(user?.roles.includes('admin') || user?.roles.includes('support')) && <ListItem>
                <ListItemContent>
                    <EditableLinkField
                        labelComponent={<LabelComponent>Пользователь <Tooltip title="Привязать своего пользователя"><IconButton size="sm" onClick={() => {
                            dispatch(partnersActions.updateEntity({id: id, data: {
                                user: usersState.entities.find(u => u.keycloak_id === user.keycloak_id),
                                types: (partnersState.entity?.types || []).concat([partnerTypesState.entities.find(t => t.name === 'Пользователь') as PartnerType])
                            }}));
                        }}><HiUserCircle /></IconButton></Tooltip></LabelComponent>}
                        value={partnersState.entity.user}
                        onApply={(value: User) => {
                            dispatch(partnersActions.updateEntity({id: id, data: {user: value}}));
                        }}
                        onSearch={(query: string) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve(usersState.entities.filter((entity) => entity.username.toLowerCase().includes(query.toLowerCase()) && entity.id !== partnersState.entity?.user?.id));
                                }, 1000);
                            });
                        }}
                    />
                </ListItemContent>
            </ListItem>}
            <ListItem>
                <ListItemContent>
                    {editable && 
                    <ModalWidget 
                        open={contactModalOpen}
                        onClose={() => setContactModalOpen(false)}
                    >
                        {partnerContactsState.entity &&
                        <ContactForm 
                            id={partnerContactsState.entity?.id} 
                            initialData={partnerContactsState.entity}
                            isLoading={partnerContactsState.loading}
                            onDecline={() => setContactModalOpen(false)}
                            onSubmit={async (contact: Contact | Partial<Contact>) => {
                                if ('id' in contact && contact.id) {
                                    dispatch(partnerContactsActions.updateEntity({id: contact.id, data: contact}));
                                } else {
                                    dispatch(partnerContactsActions.createEntity(contact));
                                }
                                setContactModalOpen(false);
                            }}
                        /> }  
                    </ModalWidget>}
                    <Typography
                        level="body-sm"
                        gutterBottom
                        className="flex text-gray-500 align-middle"
                    >
                        {PartnerContactConstants.title_ns}
                    </Typography>            
                    <ContactTable
                        partnerId={Number(id)}
                        editable={editable}
                        onEditModalAction={(contact: Contact) => {if (editable) {
                            dispatch(partnerContactsActions.selectEntity(contact));
                            setContactModalOpen(true);
                        }}}
                    />
                    {editable && <IconButton 
                        size="sm"
                        onClick={() => {
                            setContactModalOpen(true);
                            dispatch(partnerContactsActions.createEmptyEntity({partner_id: Number(id)} as Partial<Contact>));
                        }}
                    ><HiPlus /></IconButton>}
                </ListItemContent>
            </ListItem>
            <ListItem>
                <ListItemContent>
                    <Typography
                        level="body-sm"
                        gutterBottom
                        className="flex text-gray-500 align-middle"
                    >
                        {PartnerRequisiteConstants.title_ns}
                    </Typography>
                    {editable && 
                    <ModalWidget 
                        open={requisiteModalOpen}
                        onClose={() => setRequisiteModalOpen(false)}
                    >
                        {partnerRequisitesState.entity && <RequisiteForm 
                            id={partnerRequisitesState.entity?.id} 
                            initialData={partnerRequisitesState.entity}
                            isLoading={partnerRequisitesState.loading}
                            onDecline={() => setRequisiteModalOpen(false)}
                            onSubmit={async (requisite: Requisite | Partial<Requisite>) => {
                                if ('id' in requisite && requisite.id) {
                                    dispatch(partnerRequisitesActions.updateEntity({id: requisite.id, data: requisite}));
                                } else {
                                    dispatch(partnerRequisitesActions.createEntity(requisite));
                                }
                                setRequisiteModalOpen(false);
                            }}
                        />}    
                    </ModalWidget>}
                    <RequisitesTable
                        partnerId={Number(id)} 
                        editable={editable}
                        onEditModalAction={(requisite: Requisite) => {if (editable) {
                            dispatch(partnerRequisitesActions.selectEntity(requisite));
                            setRequisiteModalOpen(true);
                        }}}
                    />
                    {editable && <IconButton 
                        size="sm"
                        onClick={() => setRequisiteModalOpen(true)}
                    ><HiPlus /></IconButton>}   
                </ListItemContent>
            </ListItem> 
            <ListItem>
                <ListItemContent>
                    <Typography
                        level="body-sm"
                        gutterBottom
                        className="flex text-gray-500 align-middle"
                    >
                        {PartnerDocumentConstants.title_ns}
                    </Typography>
                    {editable && 
                    <ModalWidget 
                        open={documentModalOpen}
                        onClose={() => setDocumentModalOpen(false)}
                    >
                        <DocumentForm 
                            initialData={{partner_id: Number(id)} as UploadDocumentRequest | CreateDocumentRequest}
                            isLoading={partnerDocumentsState.loading}
                            onDecline={() => setDocumentModalOpen(false)}
                            onSubmit={async (document:  CreateDocumentRequest | UploadDocumentRequest) => {
                                if ('file_path' in document) {
                                    dispatch(partnerDocumentsActions.uploadDocument(document));
                                } else {
                                    dispatch(partnerDocumentsActions.createEntity(document));
                                }
                                setDocumentModalOpen(false);
                            }}
                        />    
                    </ModalWidget>}
                    <DocumentsTable 
                        partnerId={Number(id)}
                        editable={editable}
                    />
                    {editable && <IconButton 
                        size="sm"
                        onClick={() => setDocumentModalOpen(true)}
                    ><HiPlus /></IconButton>}   
                </ListItemContent>
            </ListItem>
        </List>
    );
};