import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseState } from '../redux-base';
import { addNotificationFx } from '../notifications/model/store';

interface EntityLoader<T extends { id: string | number }> {
    state: BaseState<T>;
    action: {
        fetchAll: () => any;
    };
    config: {
        entityName: string;
    };
}

const LOADING_PREFIX = 'entity_loading_';


const loadingStorage = {
    setLoading(entityName: string) {
        localStorage.setItem(LOADING_PREFIX + entityName, Date.now().toString());
    },

    removeLoading(entityName: string) {
        localStorage.removeItem(LOADING_PREFIX + entityName);
    },

    isLoading(entityName: string): boolean {
        const timestamp = localStorage.getItem(LOADING_PREFIX + entityName);
        if (!timestamp) return false;

        // Если прошло больше 5 секунд, считаем что загрузка зависла и разрешаем новую
        const timePassed = Date.now() - parseInt(timestamp);
        if (timePassed > 5000) {
            this.removeLoading(entityName);
            return false;
        }

        return true;
    }
};

export function useEntityLoader<T extends { id: string | number }[]>(loaders: [...{ [K in keyof T]: EntityLoader<T[K]> }], caller: string) {
    const dispatch = useDispatch();

    // Загрузка данных
    useEffect(() => {
        loaders.forEach(loader => {
            if (loader.state.loading) return;

            // Проверяем, не загружается ли уже эта сущность
            if (loadingStorage.isLoading(loader.config.entityName)) {
                console.log(`Загрузка ${loader.config.entityName} уже идет, пропускаем`);
                return;
            }
            
            // Проверка на последнее обновление
            if (loader.state.lastUpdated) {
                const lastUpdated = new Date(loader.state.lastUpdated);
                const now = new Date();
                if (now.getTime() - lastUpdated.getTime() < 10000) {
                    return;
                }
                console.log(`Загрузка ${loader.config.entityName} требуется, последнее обновление ${loader.state.lastUpdated}`);
            }

            console.log(`Загрузка ${loader.config.entityName}, вызвано из ${caller}`);
            
            loadingStorage.setLoading(loader.config.entityName);
            
            dispatch(loader.action.fetchAll())
                .finally(() => {
                    loadingStorage.removeLoading(loader.config.entityName);
                    if (process.env.NODE_ENV === 'development') {
                        addNotificationFx({
                            message: `Загрузка ${loader.config.entityName} завершена. Загружено ${loader.state.entities.length} записей`,
                            type: 'success',
                            duration: 4000,
                        })
                    }
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Возвращаем состояния загрузчиков
    return loaders.map(loader => loader.state) as [...{ [K in keyof T]: BaseState<T[K]> }];
}