import { Recruitment } from "../model/types";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/joy";

interface RecruitmentCardProps {
    data: Recruitment;
}

export const RecruitmentCard = (props: RecruitmentCardProps) => {
    const [ , setSearchParams] = useSearchParams();
    return (
      <div
        key={props.data.id}
        className="p-2 rounded shadow-sm border-gray-100 border-2"
      >
        <h3
          className="text-md mb-3 text-gray-900 hover:text-cyan-700"
          onClick={() => setSearchParams({ 
            view: 'recruitment',
            id: props.data.id.toString() })}
        >
          {props.data.recruiter.name}
        </h3>
        <div className="flex flex-col items-start mt-2">
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-gray-700 hover:text-cyan-700"
                href={`/partners/${props.data.employee.id}`}
            >
                <Typography level="body-sm">Кандидат</Typography>{props.data.employee.name}
            </a>
            <Typography level="body-sm">
                Процессинги - {props.data.processings.length}
            </Typography>
        </div>
        <div className="flex flex-row items-center mt-2 text-sm gap-2">
          <Typography level="body-sm" className="text-sm text-gray-700">Дата создания: {new Date(props.data.created_at).toLocaleDateString('ru-RU')}</Typography>
        </div>
      </div>
    );
  };