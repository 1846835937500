import { BaseService } from '../../../shared/api/base';
import { Processing } from '../model/types';

class ProcessingService extends BaseService<Processing, Processing> {
    constructor() {
        super('/processings');
    }

    async getByRecruitment(recruitmentId: string): Promise<Processing[]> {
        return this.client.get(`/recruitments/${recruitmentId}/processings`);
    }
}

export const processingService = new ProcessingService();
