import React, { Suspense, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { ProtectedRoute } from '@/processes/auth/ui/ProtectedRoute';
import { privateRoutes, publicRoutes } from './';
import { Layout } from '@/widgets/layouts/layout/ui/Layout';
import { checkAuthStatusFx } from '@/processes/auth';
import { ErrorBoundary } from '../providers/ErrorBoundary/ui/ErrorBoundary';
import { SearchProvider } from '../providers/SearchProvider';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <SearchProvider>
                    <Layout>
                        <Outlet />
                    </Layout>
                </SearchProvider>
            </ProtectedRoute>
        ),
        children: privateRoutes,
    },
    ...publicRoutes,
]);

export const AppRouter: React.FC = () => {
    useEffect(() => {
        const currentPath = window.location.pathname;
        if (!['/oidc-callback', '/jobs'].includes(currentPath)) {
            checkAuthStatusFx();
        }
    }, []);

    return (
        <ErrorBoundary>
            <Suspense fallback={
                <div className="flex items-center justify-center min-h-screen">
                    <div className="text-center">
                        <h2 className="text-xl font-semibold mb-2">Loading...</h2>
                        <p className="text-gray-600">Please wait while we load the page.</p>
                    </div>
                </div>
            }>
                <RouterProvider router={router} />
            </Suspense>    
        </ErrorBoundary>
        
    );
};
