import { Processing, ProcessingConstants, processingsActions } from "@/entities/processing";
import { Recruitment, RecruitmentReference } from "@/entities/recruitment";
import { Status } from "@/entities/status";
import { StatusReference } from "@/entities/status/ui/StatusReference";
import { Vacancy, VacancyReference } from "@/entities/vacancy";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { ModalWidget } from "@/widgets/layouts/modal/ui/Modal";
import { TableWidget } from "@/widgets/views/TableWidget";
import { Box, Button, IconButton, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import React from "react";
import { EditProcessingForm } from "./form";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { ProcessingCard } from "@/entities/processing/ui/ProcessingCard";

interface ViewProcessingsProps {
    vacancy?: Vacancy;
    recruitment?: Recruitment | Partial<Recruitment>;
}

export const ViewProcessings = ({ vacancy, recruitment }: ViewProcessingsProps) => {
    const dispatch = useAppDispatch();
    const [showDetails, setShowDetails] = React.useState(false);
    const [selectedProcessing, setSelectedProcessing] = React.useState<Processing | undefined>(undefined);
    const processings = useAppSelector(state => state.processings.entities);
    const statuses = useAppSelector(state => state.statuses.entities);
    const vacancies = useAppSelector(state => state.vacancies.entities);
    const recruitments = useAppSelector(state => state.recruitments.entities);

    return (
        <React.Fragment>
            <ModalWidget open={selectedProcessing !== undefined} onClose={() => setSelectedProcessing(undefined)}>
                <EditProcessingForm
                    processing={selectedProcessing}
                    vacancy={vacancy}
                    recruitment={recruitment}
                    onSuccess={() => {
                        setSelectedProcessing(undefined);
                    }}
                />
            </ModalWidget>
            <List>
                <ListItem>
                    <ListItemContent>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                            }}
                        >
                            <IconButton
                                variant="plain"
                                color="neutral"
                                size="sm"
                                onClick={() => setShowDetails(!showDetails)}
                            >
                                {showDetails ? <HiChevronDown /> : <HiChevronUp />}
                            </IconButton>
                            <Typography level="body-lg">
                                Процессинги
                            </Typography>
                        </Box>
                    </ListItemContent>
                </ListItem>
            </List>
            {showDetails && <><TableWidget
                objects={
                    processings
                    .filter(processing => processing.vacancy.id === vacancy?.id || processing.recruitment.id === recruitment?.id)
                    .map(processing => ({
                        ...processing,
                        employee: processing.recruitment.employee,
                    }))
                }
                renderCard={(value: Processing) => <ProcessingCard data={value} />}
                columns={[
                    { name: 'name', alias: 'Название' },
                    { name: 'vacancy', alias: 'Вакансия', mod: (id: number, value: Vacancy) => 
                        // value={vacancy}
                        // blocked={blocked}
                        // labelComponent={labelComponent}
                        // onSearch={onSearch}
                        // onCreate={onCreate}
                        // onApply={(value: Vacancy) => onApply(value)}
                        <VacancyReference
                            vacancy={value}
                            onApply={(v: Vacancy) => {}}
                            blocked={true}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(vacancies.filter((entity) => entity.title.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                        />  
                    },
                    { name: 'recruitment', alias: 'Рекрутмент', mod: (id: number, value: Recruitment) =>
                        <RecruitmentReference
                            recruitment={value}
                            onApply={(v: Recruitment) => {}}
                            blocked={true}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(recruitments.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                        />  
                    },
                    { name: 'status', alias: 'Статус', mod: (id: number, value: Status) => 
                        <StatusReference
                            status={value}
                            statuses={statuses.filter(status => status.relation === ProcessingConstants.relation)}
                            onApply={(v: Status) => dispatch(processingsActions.updateEntity({ id, data: { status: v } }))}
                            blocked={false}
                        />
                    },
                    { name: 'rate', alias: 'Ставка' },

                    { name: 'rate_type', alias: 'Тип ставки' }
                ]}
                onEdit={(value: Processing) => setSelectedProcessing(value)}
                onDelete={(value: Processing) => dispatch(processingsActions.deleteEntity(value.id))}
            />
            <Button className="w-full md:w-1/4"
                onClick={() => {
                    setSelectedProcessing({} as Processing);
                }}
            >
                {ProcessingConstants.actions.create}
            </Button></>}
        </React.Fragment>
    );
};