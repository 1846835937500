import { FC, FormEvent } from 'react';
import { Label, TextInput } from 'flowbite-react';
import { Button } from '@mui/joy';
import { Contact } from '../model/types';

export interface ContactFormProps {
    id?: number;
    onSubmit: (data: Partial<Contact>) => Promise<void>;
    onDecline?: () => void;
    initialData: Contact | Partial<Contact>;
    isLoading?: boolean;
}

export const ContactForm: FC<ContactFormProps> = ({
    id,
    onSubmit,
    initialData,
    isLoading,
    onDecline,
}) => {
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        
        await onSubmit({
            name: formData.get('name') as string,
            email: formData.get('email') as string,
            phone: formData.get('phone') as string,
            partner_id: initialData.partner_id,
            ...(id && {id})
        });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Имя контакта" />
                </div>
                <TextInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Имя контакта"
                    required
                    disabled={isLoading}
                    defaultValue={initialData?.name}
                />
            </div>     
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Email" />
                </div>
                <TextInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    disabled={isLoading}
                    defaultValue={initialData?.email}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="phone" value="Телефон" />
                </div>
                <TextInput
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder="Телефон"
                    disabled={isLoading}
                    defaultValue={initialData?.phone}
                />
            </div>    
            <div className="col-span-2 flex justify-end gap-2">
                <Button
                    type="button"
                    onClick={onDecline}
                    disabled={isLoading}
                    color="neutral"
                >
                    Отмена
                </Button>
                
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
