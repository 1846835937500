const ns = "partner";
const relation = "partner";

const acitons = {
    create: "Создать контрагента",
    edit: "Редактировать контрагента",
    view: "Просмотреть контрагента",
};

// namespace title
const title_ns = "Контрагенты";

const title = "Контрагент";

export const PartnerConstants = {
    ns,
    relation,
    title_ns,
    title,
    acitons,
};