import { EditableMultiLinkField } from "@/shared";
import { PartnerType } from "../model/types";

interface PartnerTypeReferenceProps {
    pts: PartnerType[]; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (values: PartnerType[]) => void;
    onSearch: (query: string) => Promise<PartnerType[] | string[]>;
};

export const PartnerTypeReference = ({pts, blocked, labelComponent, onApply, onSearch}: PartnerTypeReferenceProps) => {
    return (
        <EditableMultiLinkField 
            value={pts}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch}
            onApply={(values: PartnerType[]) => onApply(values)}
        />
    )
};