import { BaseService } from '../../../shared/api/base';
import { PartnerType, CreatePartnerTypeRequest } from '../model/types';

class PartnerTypeService extends BaseService<PartnerType, CreatePartnerTypeRequest> {
    constructor() {
        super('/utils/partner_types');
    }
}

export const partnerTypeService = new PartnerTypeService();
