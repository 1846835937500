import { Typography } from "@mui/joy"
import React, { PropsWithChildren } from "react"

interface Props {
    component?: "p" | "span"
}

export const LabelComponent: React.FC<PropsWithChildren<Props>> = ({ component = "p", children}: PropsWithChildren<Props>) => {
    return (
        <Typography
          level="body-sm"
          gutterBottom
          className="text-gray-500"
          component={component}
        >
          {children}
        </Typography>
    )
}