import { BaseService } from '../../../shared/api/base';
import { CreateRateTypeRequest, RateType } from '../model/types';

class RateTypeService extends BaseService<RateType, CreateRateTypeRequest> {
    constructor() {
        super('/rate-types');
    }
}

export const rateTypeService = new RateTypeService();
