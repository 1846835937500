import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditPartnerSchema } from './types';

const initialState: EditPartnerSchema = {
    isLoading: false,
    error: undefined,
};

export const editPartnerSlice = createSlice({
    name: 'editPartner',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
    },
});

export const { actions: editPartnerActions } = editPartnerSlice;
export const { reducer: editPartnerReducer } = editPartnerSlice;
