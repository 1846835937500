import { BaseService } from '../../../shared/api/base';
import { Stack, CreateStackRequest } from '../model/types';

class StacksService extends BaseService<Stack, CreateStackRequest> {
    constructor() {
        super('/stacks');
    }
}

export const stacksService = new StacksService();
