import { createBaseSlice } from '@/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { partnerDocumentService } from '../api/partnerDocumentService';
import { Document, UploadDocumentRequest } from './types';
const { slice, thunks } = createBaseSlice<Document>(
    'partnerDocuments', 
    partnerDocumentService,
    (builder: any) => {
        builder.addCase(uploadDocument.fulfilled, (state: any, action: any) => {
            state.entities.push(action.payload);
            state.lastUpdated = new Date().toISOString();
            state.loading = false;
        });
    }
);

const uploadDocument = createAsyncThunk(
    'partnerDocuments/upload',
    async (data: UploadDocumentRequest) => {
        return partnerDocumentService.upload(data);
    }
);

export const partnerDocumentsReducer = slice.reducer;
export const partnerDocumentsActions = { 
    ...slice.actions, 
    ...thunks,
    uploadDocument
};