import { useRef } from "react";

function useDebounce(fn: Function, timeoutMs: number) {
    // в ссылках хранится id таймера и время предидущего вызова функции
    const lastCallTimerRef = useRef<any>(null);
    const lastCallRef = useRef<any>(null);
  
    return function perform(...args: any[]) {
      // при повторном вызове функции текущее время прошлого вызова становится прошлым временем для текущего вызова
      const previousCall = lastCallRef.current;
      lastCallRef.current = Date.now();
  
      if (previousCall && lastCallRef.current - previousCall <= timeoutMs) {
        clearTimeout(lastCallTimerRef.current);
      }
      lastCallTimerRef.current = window.setTimeout(() => fn(...args), timeoutMs);
    };
  };

export default useDebounce;