import { Recruitment, recruitmentsActions } from "@/entities/recruitment";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { IconButton, Typography } from "@mui/joy";
import { HiSave } from "react-icons/hi";

export const RecruitmentHeadTitle = () => {
    const recruitment = useAppSelector(state => state.recruitments.entity as Recruitment);
    return (
        <Typography component={'div'} className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {recruitment.name}
        </Typography>
    )
}

export const RecruitmentHeadActions = () => {
    const recruitment = useAppSelector(state => state.recruitments.entity as Recruitment);
    const dispatch = useAppDispatch();
    const handleCreateRecruitment = () => {
        if (!recruitment) return;
        if (recruitment.id) return;

        if (!recruitment?.employee?.id) {
            window.alert("Кандидат обязателен")
            return
        }; 
        if (!recruitment?.recruiter?.id) {
            window.alert("Рекрутер обязателен")
            return
        } 

        dispatch(recruitmentsActions.createEntity({
          recruiter: recruitment.recruiter,
          employee: recruitment.employee,
          status: recruitment.status,
          comment: recruitment.comment
        } as Recruitment));
    };

    return (
        recruitment.id ? (
            <div className="flex flex-row gap-3 justify-end items-center">
              <div className="flex flex-col gap-2">
                <Typography
                  level="body-sm"
                  gutterBottom
                  className="text-gray-500"
                >
                  Дата начала:{" "}
                  {new Date(recruitment.created_at).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
                <Typography
                  level="body-sm"
                  gutterBottom
                  className="text-gray-500"
                >
                  Дата обновления:{" "}
                  {new Date(recruitment.updated_at).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>  
              </div>
            </div>
          ) : (
            <IconButton
              size="lg"
              variant="solid"
              onClick={() => handleCreateRecruitment()}
            >
              <HiSave />
            </IconButton>
          )
    )
};