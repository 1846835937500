import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// Lazy-loaded page imports
const PartnersPage = lazy<React.ComponentType<{}>>(() => import('@/pages/partners'));
const DashboardPage = lazy<React.ComponentType<{}>>(() => import('@/pages/dashboard'));
const VacanciesPage = lazy<React.ComponentType<{}>>(() => import('@/pages/vacancies'));
const PublicVacanciesPage = lazy<React.ComponentType<{}>>(() => import('@/pages/vacancies/ui/PublicPage'));
// const VacancyDetailsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/vacancies/details'));
// const PartnerDetailsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/partners/details'));
const RecruitmentsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/recruitments'));
// const RecruitmentDetailsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/recruitments/details'));
const ProcessingsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/processings'));
// const ProcessingDetailsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/processings/details'));
// const ScoringsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/scorings'));
// const ScoringDetailsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/scorings/details'));
const SettingsPage = lazy<React.ComponentType<{}>>(() => import('@/pages/settings'));
const AuthCallback = lazy<React.ComponentType<{}>>(() => import('@/processes/auth/ui/AuthCallback'));

export const privateRoutes: RouteObject[] = [
    {
        path: '/',
        element: <DashboardPage />,
    },
    {
        path: '/vacancies',
        children: [
            {
                index: true,
                element: <VacanciesPage />,
            },]
    },
    {
        path: '/partners',
        children: [
            {
                index: true,
                element: <PartnersPage />,
            },
        ],
    },
    {
        path: '/recruitments',
        children: [
            {
                index: true,
                element: <RecruitmentsPage />,
            },
        ],
    },
    {
        path: '/processings',
        children: [
            {
                index: true,
                element: <ProcessingsPage />,
            },
        ],
    },
    // {
    //     path: '/processings',
    //     children: [
    //         {
    //             index: true,
    //             element: <ProcessingsPage />,
    //         },
    //         {
    //             path: ':id',
    //             element: <ProcessingDetailsPage />,
    //         },
    //     ],
    // },
    // {
    //     path: '/scorings',
    //     children: [
    //         {
    //             index: true,
    //             element: <ScoringsPage />,
    //         },
    //         {
    //             path: ':id',
    //             element: <ScoringDetailsPage />,
    //         },
    //     ],
    // },
    {
        path: '/settings/*',
        element: <SettingsPage />,
    },
];

export const publicRoutes: RouteObject[] = [
    {
        path: '/oidc-callback',
        element: <AuthCallback />,
    },
    {
        path: '/jobs',
        element: <PublicVacanciesPage />,
    },
];
