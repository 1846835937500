import { EditableLinkField } from "@/shared";
import { Recruitment } from "../model/types";
import { useAppSelector } from "@/shared/lib/hooks";

interface RecruitmentReferenceProps {
    recruitment?: Recruitment; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (value: Recruitment) => void;
    onSearch?: (query: string) => Promise<Recruitment[] | string[]>;
    onCreate?: (value: string) => Promise<Recruitment>;
};

export const RecruitmentReference = ({recruitment, blocked, labelComponent, onApply, onSearch, onCreate}: RecruitmentReferenceProps) => {
    const recruitments = useAppSelector(state => state.recruitments.entities);
    const search = async (query: string) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(recruitments.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
            }, 1000);
        }) as Promise<Recruitment[]>;
    }
    return (
        <EditableLinkField 
            value={recruitment}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch || search}
            onCreate={onCreate}
            onApply={(value: Recruitment) => onApply(value)}
        />
    )
};