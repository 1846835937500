const ns = "partnerContact";

const acitons = {
    create: "Создать контакт",
    edit: "Редактировать контакт",
    view: "Просмотреть контакт",
};

// namespace title
const title_ns = "Контакты";

const title = "Контакт";

export const PartnerContactConstants = {
    ns,
    title_ns,
    title,
    acitons,
};