import { Box } from '@mui/joy';
import { useEffect, useState } from 'react';
import { KanbanProps } from '../model/types';
import { KanbanColumn } from './KanbanColumn';
import '../lib/styles.css';

export const Kanban = ({ columns, onDragEnd }: KanbanProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [draggedCard, setDraggedCard] = useState<string | null>(null);
  const [sourceColumnId, setSourceColumnId] = useState<string | null>(null);
  const [draggedOverColumn, setDraggedOverColumn] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const ismobile = window.innerWidth < 1024;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  const handleDragStart = (cardId: string, columnId: string) => {
    setDraggedCard(cardId);
    setSourceColumnId(columnId);
  };

  const handleDragOver = (e: React.DragEvent, columnId: string) => {
    e.preventDefault();
    if (columnId !== draggedOverColumn) {
      setDraggedOverColumn(columnId);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!relatedTarget?.closest('.kanban-column')) {
      setDraggedOverColumn(null);
    }
  };

  const handleDrop = (columnId: string) => {
    setDraggedOverColumn(null);
    // Проверяем, что карточка перетаскивается в другую колонку
    if (draggedCard && sourceColumnId !== columnId) {
      onDragEnd(draggedCard, columnId);
    }
    setDraggedCard(null);
    setSourceColumnId(null);
  };

  return (
    <Box
      className="flex flex-col md:flex-row gap-4 w-full overflow-x-auto pb-4"
      sx={{
        minHeight: { md: "calc(100vh - 200px)" },
      }}
    >
      {columns.map((column) => (
        <Box
          key={column.id}
          className={`kanban-column w-full md:w-80 flex-shrink-0 ${
            draggedOverColumn === column.id.toString() ? 'highlight-drop-target' : ''
          }`}
          onDragOver={(e) => handleDragOver(e, column.id.toString())}
          onDragLeave={handleDragLeave}
          onDrop={() => handleDrop(column.id.toString())}
        >
          <KanbanColumn
            title={column.title}
            color={column.color}
            cards={column.cards}
            onDragStart={(cardId) => handleDragStart(cardId, column.id.toString())}
          />
        </Box>
      ))}
    </Box>
  );
};
