const ns = "partnerRequisite";

const acitons = {
    create: "Добавить реквизит",
    edit: "Редактировать реквизит",
    view: "Просмотреть реквизит",
};

// namespace title
const title_ns = "Реквизиты";

const title = "Реквизит";

export const PartnerRequisiteConstants = {
    ns,
    title_ns,
    title,
    acitons,
};