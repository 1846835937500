import { Alert } from '@mui/joy';
import { useEffect, useState, useRef } from 'react';
import { Notification } from '../../lib/notifications/model/types';
import { removeNotificationFx } from '../../lib/notifications/model/store';

interface NotificationItemProps {
    notification: Notification;
}

const NOTIFICATION_TIMEOUT = 10000; // 15 seconds
const SWIPE_THRESHOLD = 0.5; // Порог для смахивания (50% ширины)

export const NotificationItem = ({ notification }: NotificationItemProps) => {
    const [isRemoving, setIsRemoving] = useState(false);
    const [isAppearing, setIsAppearing] = useState(true);
    const [dragX, setDragX] = useState(0);
    const notificationRef = useRef<HTMLDivElement>(null);
    const dragStartX = useRef<number | null>(null);
    const isDragging = useRef(false);
    const removeTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        requestAnimationFrame(() => setIsAppearing(false));

        removeTimeout.current = setTimeout(() => {
            handleRemove();
        }, NOTIFICATION_TIMEOUT);

        return () => {
            if (removeTimeout.current) {
                clearTimeout(removeTimeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification.id]);

    const handleRemove = () => {
        if (removeTimeout.current) {
            clearTimeout(removeTimeout.current);
        }
        setIsRemoving(true);
        setTimeout(() => {
            removeNotificationFx(notification.id);
        }, 300);
    };

    const handleClose = () => {
        handleRemove();
    };

    const handleDrag = (clientX: number) => {
        if (dragStartX.current === null) return;
        const deltaX = clientX - dragStartX.current;
        setDragX(deltaX);
    };

    const handleDragEnd = () => {
        if (!notificationRef.current || dragStartX.current === null) return;

        notificationRef.current.style.setProperty('transition', 'transform 0.3s ease-out');
        const width = notificationRef.current.offsetWidth;

        if (Math.abs(dragX) > width * SWIPE_THRESHOLD) {
            const direction = dragX > 0 ? width : -width;
            setDragX(direction);
            handleRemove();
        } else {
            setDragX(0);
        }

        isDragging.current = false;
        dragStartX.current = null;
    };

    // Обработчики для тач-устройств
    const handleTouchStart = (e: React.TouchEvent) => {
        isDragging.current = true;
        dragStartX.current = e.touches[0].clientX;
        notificationRef.current?.style.setProperty('transition', 'none');
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging.current) return;
        handleDrag(e.touches[0].clientX);
        
        // Предотвращаем скролл при горизонтальном свайпе
        if (Math.abs(dragX) > 10) {
            e.preventDefault();
        }
    };

    return (
        <div 
            ref={notificationRef}
            className={`relative mb-2 transition-all duration-300 ease-out transform cursor-grab active:cursor-grabbing ${
                isAppearing 
                    ? '-translate-x-full opacity-0' 
                    : isRemoving 
                        ? 'translate-x-full opacity-0' 
                        : 'translate-x-0 opacity-100'
            }`}
            style={{
                transform: `translateX(${dragX}px)`,
                transformOrigin: 'right',
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleDragEnd}
        >
            <Alert
                variant="soft"
                color={notification.type}
                className="relative overflow-hidden"
                endDecorator={
                    <button 
                        onClick={handleClose} 
                        className="ml-2 z-10 opacity-60 hover:opacity-100 transition-opacity"
                    >
                        ✕
                    </button>
                }
            >
                {notification.message}
                <div 
                    className="absolute bottom-0 left-0 h-1 bg-current opacity-20"
                    style={{ 
                        animation: `progress ${notification.duration|| NOTIFICATION_TIMEOUT}ms linear`,
                        width: '100%',
                    }}
                />
            </Alert>
            <style>{`
                @keyframes progress {
                    from {
                        transform: scaleX(1);
                    }
                    to {
                        transform: scaleX(0);
                    }
                }
            `}</style>
        </div>
    );
};
