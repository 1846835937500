import axios, { AxiosInstance } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const createApiInstance = (baseURL: string = API_BASE_URL): AxiosInstance => {
    const instance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true, // Включаем поддержку куки глобально
    });

    // Add response interceptor to handle cookie-related errors
    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response?.status === 400 && 
                error.response?.data?.message?.includes('Set-Cookie')) {
                console.error('Cookie size limit exceeded');
                // Можно добавить логику очистки куки или другую обработку
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export const baseApi = createApiInstance();

export class BaseService<T, U> {
    protected client: AxiosInstance;
    protected baseUrl: string;

    constructor(endpoint: string, client: AxiosInstance = baseApi) {
        this.client = client;
        this.baseUrl = endpoint;
    }

    async getAll(params?: Record<string, any>): Promise<T[]> {
        const { data } = await this.client.get<T[]>(this.baseUrl, { params });
        return data;
    }

    async getById(id: string | number): Promise<T> {
        const { data } = await this.client.get<T>(`${this.baseUrl}/${id}`);
        return data;
    }

    async create(payload: U): Promise<T> {
        const { data } = await this.client.post<T>(this.baseUrl, payload);
        return data;
    }

    async update(id: string | number, payload: Partial<U>): Promise<T> {
        const { data } = await this.client.put<T>(`${this.baseUrl}/${id}`, payload);
        return data;
    }

    async delete(id: string | number): Promise<void> {
        await this.client.delete(`${this.baseUrl}/${id}`);
    }

    protected async get<R = T>(url: string, params?: Record<string, any>): Promise<R> {
        const { data } = await this.client.get<R>(url, { params });
        return data;
    }

    protected async post<R = T>(url: string, payload: any): Promise<R> {
        const { data } = await this.client.post<R>(url, payload);
        return data;
    }

    protected async patch<R = T>(url: string, payload: any): Promise<R> {
        const { data } = await this.client.patch<R>(url, payload);
        return data;
    }

    protected async put<R = T>(url: string, payload: any): Promise<R> {
        const { data } = await this.client.put<R>(url, payload);
        return data;
    }
}
