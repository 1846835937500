export function flattenValues(val: any): string | number | boolean | undefined {
    if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") return val;
    if (!val) return undefined;
    if (val.name) return val.name;
    if (val.username) return val.username;
    if (val.title) return val.title;
    if (val.alias) return val.alias;
    if (val.comment) return val.comment;
    if (val.value && (typeof val.value === "string" || typeof val.value === "number" || typeof val === "boolean")) return val.value;
    return undefined;
}

export function flattenKeys(val: any): string | number | boolean | undefined {
    if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") return val;
    if (!val) return undefined;
    if (val.id) return val.id;
    if (val.key) return val.key;
    if (val.name) return val.name;
    return undefined;
}
