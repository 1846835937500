import { EditableLinkField } from "@/shared";
import { Vacancy } from "../model/types";
import { useAppSelector } from "@/shared/lib/hooks";

interface VacancyReferenceProps {
    vacancy?: Vacancy; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (value: Vacancy) => void;
    onSearch?: (query: string) => Promise<Vacancy[] | string[]>;
    onCreate?: (value: string) => Promise<Vacancy>;
};

export const VacancyReference = ({vacancy, blocked, labelComponent, onApply, onSearch, onCreate}: VacancyReferenceProps) => {
    const vacancies = useAppSelector(state => state.vacancies.entities);
    const search = async (query: string) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(vacancies.filter((entity) => entity.title.toLowerCase().includes(query.toLowerCase())));
            }, 1000);
        }) as Promise<Vacancy[]>;
    }
    
    return (
        <EditableLinkField 
            value={vacancy}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch || search}
            onCreate={onCreate}
            onApply={(value: Vacancy) => onApply(value)}
        />
    )
};