const ns = "processing";
const relation = "processing";

const actions = {
    create: "Создать задачу процессинга",
    edit: "Редактировать задачи процессинга",
};

// namespace title
const title_ns = "Задачи процессинга";

const title = "Процессинг";

export const ProcessingConstants = {
    ns,
    title_ns,
    relation,
    title,
    actions,
};