import React, { useState } from "react";
import { Box, Typography, IconButton, Sheet, Table, Checkbox, List, ListItem, ListItemContent, ListItemDecorator, Avatar } from "@mui/joy";
import { HiChevronDown, HiChevronUp, HiMinus, HiPencil, HiTrash } from "react-icons/hi";
import { OrderableColProps, TableProps } from './types';
import { Badge } from "flowbite-react";
import { flattenKeys, flattenValues } from "../../../shared/lib";

/**
 * Compare two values for descending order
 */
function descendingComparator(a: any, b: any, orderBy: string): number {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if ((!aValue && aValue !== 0) || (!bValue && bValue !== 0)) return 0;
    
    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

/**
 * Get comparator function based on order direction
 */
function getComparator(order: string, orderBy: string): (a: any, b: any) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Orderable column header component
 */
const OrderableCol: React.FC<OrderableColProps> = ({ name, alias, setOrder, order }) => {
    return (
        <Box className="flex flex-row items-center gap-2">
            <Typography level="body-sm">{alias || name}</Typography>
            <IconButton
                size="sm"
                variant="plain"
                onClick={() => setOrder(name, order)}
            >
                {order === "asc" ? <HiChevronUp /> : order === "desc" ? <HiChevronDown /> : <HiMinus />}
            </IconButton>
        </Box>
    )
}

/**
 * Switch order direction
 */
function switchOrder( order: string ): string {
    if (order === 'asc') return 'desc';
    if (order === 'desc') return 'none';
    return 'asc';
}

/**
 * Main table component with sorting and filtering capabilities
 */
const TableAdmin: React.FC<TableProps> = ({ 
    data, 
    columns, 
    onOrderChange, 
    onClick, 
    orderableColumns = [], 
    checkbox = false, 
    additionalActions = [],
    onDelete,
    onEdit,
    renderCard
}) => {
    const [orderCol, setOrderCol] = useState({name: "", order: "none"});
    const [cols] = useState(columns);
    const [selected, setSelected] = useState<string[]>([]);

    const handleOrderChange = (name: string, order: string) => {
        const newOrder = switchOrder(order);
        setOrderCol({name, order: newOrder});
        if (onOrderChange) onOrderChange({name, order: newOrder});
    };

    // const handleCheckboxChange = (id: string) => {
    //     if (selected.includes(id)) {
    //         setSelected(selected.filter(item => item !== id));
    //     } else {
    //         setSelected([...selected, id]);
    //     }
    // };

    // const handleSelectAll = () => {
    //     if (selected.length === data.length) {
    //         setSelected([]);
    //     } else {
    //         setSelected(data.map(item => item.id));
    //     }
    // };

    /**
     * Sort array based on current order column and direction
     */
    function sortData(arr: any[]): any[] {
        if (orderCol.order === "none" || !orderCol.name) return arr;
        return arr.sort(getComparator(orderCol.order, orderCol.name));
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Sheet
                className="OrderTableContainer"
                sx={{
                    display: { xs: 'none', sm: 'initial' },
                    width: '100%',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    variant="plain"
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                        <tr className="bg-gray-50 bg-opacity-100">
                            {checkbox && 
                            (<th key="thcb" style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                                <Checkbox
                                    size="sm"
                                    indeterminate={
                                        selected.length > 0 && selected.length !== data.length
                                    }
                                    checked={selected.length === data.length}
                                    onChange={(event) => {
                                        setSelected(
                                        event.target.checked ? data.map((row) => row.id) : [],
                                        );
                                    }}
                                    color={
                                        selected.length > 0 || selected.length === data.length
                                        ? 'primary'
                                        : undefined
                                    }
                                    sx={{ verticalAlign: 'text-bottom' }}
                                />
                            </th>)
                            }
                            {
                            cols.map(col => <th key={col.name}  style={{ width: col.width || '120px', padding: '12px 6px' }}>
                                {
                                orderableColumns.includes(col.name)
                                    ? <OrderableCol key={col.name} name={col.name} alias={col.alias} setOrder={handleOrderChange} order={col.name === orderCol.name ? orderCol.order : 'none'}/>
                                    : col.alias
                                }
                            </th>)
                            }
                            <th style={{ width: 140, padding: '12px 6px' }}> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {sortData([...data]).map((row) => (
                        <tr key={row.id}>
                            {checkbox &&  (
                                    <td style={{ textAlign: 'center', width: 120 }}>
                                        <Checkbox
                                            size="sm"
                                            checked={selected.includes(row.id)}
                                            color={selected.includes(row.id) ? 'primary' : undefined}
                                            onChange={(event) => {
                                            setSelected((ids) =>
                                                event.target.checked
                                                ? ids.concat(row.id)
                                                : ids.filter((itemId) => itemId !== row.id),
                                            );
                                            }}
                                            slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                            sx={{ verticalAlign: 'text-bottom' }}
                                        />
                                    </td>
                            )}
                            {cols.map(col => (
                                <td key={col.name} onClick={() => onClick ? onClick(row.id) : {}}>
                                    {Array.isArray(row[col.name])
                                    ?   row[col.name].map((val: any) => 
                                        <Badge
                                            key={flattenKeys(val)?.toString()}
                                            color={val.color || "info"}
                                        >
                                            {flattenValues(val)}
                                        </Badge>)
                                    
                                    :   (col.badges
                                        ?   <Badge color={col.badges.find((value: any, index: number) => (row[col.name].id || row[col.name]) === (value?.id || flattenKeys(value)))?.color}>
                                            {col.badges.find((value: any, index: number) => (row[col.name].id || row[col.name]) === (value?.id || flattenKeys(value) ))?.name}
                                        </Badge>
                                        : <Typography level="body-xs">{col.mod ? col.mod(flattenKeys(row) as number, row[col.name]) : flattenValues(row[col.name])}</Typography>
                                    )}
                                </td>
                            ))}
                            <td className="flex-1">
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: 'fit-content' }}>
                                    {onDelete && <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => onDelete(row.id)}
                                    ><HiTrash /></IconButton>}
                                    {onEdit && <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => onEdit(flattenKeys(row) as number)}
                                    ><HiPencil /></IconButton>}
                                    {additionalActions && additionalActions.map(action => 
                                        <IconButton
                                            size="sm"
                                            variant="outlined"
                                            color="neutral"
                                            onClick={() => action.action(flattenKeys(row) as number)}
                                        >{action.decorator}
                                        </IconButton>
                                        )}
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {sortData([...data]).map((row, index) => 
                (renderCard
                    ? (<Box className="grid grid-cols-6 justify-between">
                            <Box className="col-span-5 flex-grow">
                                {renderCard(row)}    
                            </Box>
                            {(onDelete || onEdit) && <Box className="flex flex-col gap-2 col-span-1">
                                {onDelete && <IconButton
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => onDelete(row.id)}
                                ><HiTrash /></IconButton>}
                                {onEdit && <IconButton
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => onEdit(flattenKeys(row) as number)}
                                ><HiPencil /></IconButton>}
                            </Box>}
                        </Box>)
                    : (<List key={index} size="sm" sx={{ '--ListItem-paddingX': 0 }}>
                        <ListItem
                            key={row.id}
                            sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            }}
                        >
                            <ListItemContent sx={{ display: 'flex', gap: 1, alignItems: 'start', flexDirection: "column" }}>
                                {flattenValues(row.name) && <ListItemDecorator>
                                    <Avatar size="sm">{Array.from(flattenValues(row.name)?.toString() || "User")[0].toUpperCase()}</Avatar>
                                </ListItemDecorator>}
                                {cols.map(col => (
                                    Array.isArray(row[col.name])
                                    ?   row[col.name].map((val: any) => 
                                        <Badge
                                            key={flattenKeys(val)?.toString()}
                                            color={val.color || "info"}
                                        >
                                            {flattenValues(val)}
                                        </Badge>)
                                    
                                    : <Typography level="body-xs" gutterBottom>
                                        {col.mod ? col.mod(flattenKeys(row) as number, flattenValues(row[col.name])) : flattenValues(row[col.name])}
                                    </Typography>
                                ))}
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => onDelete ? onDelete(row.id) : {}}
                                    ><HiTrash /></IconButton>
                                </Box>
                            </ListItemContent>
                        </ListItem>
                    </List>)
                )
            )}
        </Box>
    </Box>
    );
};

export { TableAdmin };
