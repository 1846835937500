import { extendTheme } from '@mui/joy/styles';

export const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#F0F7FF',
                    100: '#C2E0FF',
                    200: '#99CCF3',
                    300: '#66B2FF',
                    400: '#3399FF',
                    500: '#007FFF',
                    600: '#0072E5',
                    700: '#0059B2',
                    800: '#004C99',
                    900: '#003A75',
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    50: '#E2EDF8',
                    100: '#C5D9E8',
                    200: '#A7C5D9',
                    300: '#8AB1C9',
                    400: '#6C9DBA',
                    500: '#4E89AB',
                    600: '#3F7A9C',
                    700: '#306B8D',
                    800: '#215C7E',
                    900: '#124D6F',
                },
            },
        },
    },
    fontFamily: {
        body: "'Inter', var(--joy-fontFamily-fallback)",
        display: "'Inter', var(--joy-fontFamily-fallback)",
    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.size === 'md' && {
                        borderRadius: '8px',
                        paddingBlock: '8px',
                        paddingInline: '16px',
                    }),
                }),
            },
        },
        JoyInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        JoyCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            },
        },
    },
});
