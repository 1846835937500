import { Box } from '@mui/joy';
import { Badge } from 'flowbite-react';
import { useState, useRef, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { HiMinusCircle, HiPlusCircle } from 'react-icons/hi';
import { KanbanCard } from '../model/types';
import { $collapsedColumns, toggleColumnCollapse } from '../model/store';
import '../lib/styles.css';

interface KanbanColumnProps {
  /** Заголовок колонки */
  title: string;
  /** Цвет бейджа колонки */
  color: string;
  /** Массив карточек */
  cards: KanbanCard[];
  /** Обработчик начала перетаскивания */
  onDragStart: (cardId: string) => void;
}

export const KanbanColumn = ({ 
  title, 
  color, 
  cards, 
  onDragStart 
}: KanbanColumnProps) => {
  const [draggedOverIndex, setDraggedOverIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [badgeState, setBadgeState] = useState<'none' | 'increase' | 'decrease'>('none');
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const prevCardsLength = useRef(cards.length);

  // Получаем состояние свернутости из effector store
  const collapsedColumns = useUnit($collapsedColumns);
  const isCollapsed = collapsedColumns[title] || false;

  useEffect(() => {
    if (contentRef.current && !isCollapsed) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [cards, isCollapsed]);

  useEffect(() => {
    if (cards.length !== prevCardsLength.current) {
      setIsAnimating(true);
      const animationTimer = setTimeout(() => {
        setIsAnimating(false);
      }, 300);

      setBadgeState(cards.length > prevCardsLength.current ? 'increase' : 'decrease');
      const badgeTimer = setTimeout(() => {
        setBadgeState('none');
      }, 400);

      prevCardsLength.current = cards.length;
      
      return () => {
        clearTimeout(animationTimer);
        clearTimeout(badgeTimer);
      };
    }
  }, [cards.length]);

  const handleDragOver = (e: React.DragEvent, index: number) => {
    e.preventDefault();
    if (isCollapsed) return;
    
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const y = e.clientY - rect.top;
    const height = rect.height;
    
    const position = y > height / 2 ? index + 1 : index;
    setDraggedOverIndex(position);
  };

  const handleDragStart = (cardId: string) => {
    if (isCollapsed) return;
    setIsDragging(cardId);
    onDragStart(cardId);
  };

  const handleDragEnd = () => {
    setIsDragging(null);
    setDraggedOverIndex(null);
  };

  const handleToggleCollapse = () => {
    toggleColumnCollapse(title);
  };

  return (
    <Box
      className="bg-white rounded px-2 py-2 h-fit"
      sx={{ minHeight: { md: isCollapsed ? "auto" : "100%" } }}
    >
      <div 
        className="flex flex-row justify-between items-center mb-2 mx-1 column-header"
        onClick={handleToggleCollapse}
      >
        <div className="flex items-center">
          <Badge color={color as any}>{title}</Badge>
          <div 
            className={`badge-counter pl-1 text-gray-400 text-sm ${
              badgeState !== 'none' ? `updating ${badgeState}` : ''
            }`}
          >
            {cards?.length}
          </div>
        </div>
        {isCollapsed ? <HiPlusCircle /> : <HiMinusCircle />}
      </div>
      <div 
        ref={contentRef}
        className={`column-content ${isCollapsed ? 'collapsed' : ''}`}
        style={{ height: isCollapsed ? 0 : contentHeight || 'auto' }}
        onDragOver={(e) => {
          e.preventDefault();
          if (!isCollapsed && cards.length === 0) {
            setDraggedOverIndex(0);
          }
        }}
      >
        {cards.map((card, i) => (
          <div key={card.id}>
            <div
              className={`kanban-card mb-2 cursor-move ${
                isDragging === card.id.toString() ? 'dragging' : ''
              } ${isAnimating ? (cards.length > prevCardsLength.current ? 'expanded' : 'collapsed') : ''}`}
              draggable
              onDragStart={() => handleDragStart(card.id.toString())}
              onDragEnd={handleDragEnd}
              onDragOver={(e) => handleDragOver(e, i)}
            >
              {card.child}
            </div>
            {draggedOverIndex === i && <div className="drop-indicator" />}
          </div>
        ))}
        {draggedOverIndex === cards.length && <div className="drop-indicator" />}
      </div>
    </Box>
  );
};
