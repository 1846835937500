const ns = "recruitment";
const relation = "recruitment";

const acitons = {
    create: "Создать задачу рекрутмента",
    edit: "Редактировать задачи рекрутмента",
};

// namespace title
const title_ns = "Задачи рекрутмента";

const title = "Рекрутмент";

export const RecruitmentConstants = {
    ns,
    title_ns,
    relation,
    title,
    acitons,
};