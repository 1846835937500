import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { baseApi } from './base';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

class AuthService {
    protected client: AxiosInstance;

    constructor() {
        this.client = baseApi;
        this.client.defaults.withCredentials = true;
        
        // Add response interceptor to handle cookie-related errors
        this.client.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 400 && 
                    error.response?.data?.message?.includes('Set-Cookie')) {
                    console.error('Cookie size limit exceeded');
                    // Можно добавить логику очистки куки или другую обработку
                }
                return Promise.reject(error);
            }
        );
    }

    get<T = any>(url: string): Promise<AxiosResponse<T>> {
        return this.client.get<T>(url);
    };
}

interface AuthResponse {
    isAuthenticated: boolean;
    user: any; // TODO: Add user type
}

interface LoginResponse {
    loginUrl: string;
    tokenUrl: string;
}

interface TokenResponse {
    accessToken: string;
    user: any; // TODO: Add user type
}

interface LogoutResponse {
    logoutUrl?: string;
}

export const authApi = {
    checkStatus: () => 
        axios.get<AuthResponse>(`${API_BASE_URL}/api/auth/status`),
    
    initiateLogin: (redirectUri: string) =>
        new AuthService().get<LoginResponse>(`${API_BASE_URL}/api/auth/login?redirect_uri=${redirectUri}`),
    
    handleCallback: (tokenUrl: string, code: string, state: string) =>
        new AuthService().get<TokenResponse>(`${API_BASE_URL}${tokenUrl}?code=${code}&state=${state}`),
    
    logout: () =>
        new AuthService().get<LogoutResponse>(`${API_BASE_URL}/api/auth/logout`),
};
