import { Partner, PartnerConstants, partnersActions } from "@/entities/partner";
import { Contact, PartnerContactConstants, partnerContactsActions } from "@/entities/partner-contact";
import { Document, PartnerDocumentConstants, partnerDocumentsActions } from "@/entities/partner-document";
import { PartnerRequisiteConstants, partnerRequisitesActions, Requisite } from "@/entities/partner-requisite";
import { PartnerType, PartnerTypeConstants, partnerTypesActions } from "@/entities/partner-type";
import { PartnerHeadActions, PartnerHeadTitle, PartnerView } from "@/features/partner";
import { useAppDispatch, useAppSelector, useEntityLoader } from "@/shared/lib/hooks";
import { DrawerWidget } from "@/widgets/layouts/drawer/ui/Drawer";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom"

export const PartnerDrawer = () => {
    const [ searchParams ] = useSearchParams();
    const dispatch = useAppDispatch();

    // Загружаем все данные для Drawer
    useEntityLoader<[Partner, PartnerType, Contact, Requisite, Document]>([
        {
            state: useAppSelector(state => state.partners),
            action: partnersActions,
            config: { entityName: PartnerConstants.title }
        },
        {
            state: useAppSelector(state => state.partnerTypes),
            action: partnerTypesActions,
            config: { entityName: PartnerTypeConstants.title_ns }
        },
        {
            state: useAppSelector(state => state.partnerContacts),
            action: partnerContactsActions,
            config: { entityName: PartnerContactConstants.title_ns }
        },
        {
            state: useAppSelector(state => state.partnerRequisites),
            action: partnerRequisitesActions,
            config: { entityName: PartnerRequisiteConstants.title_ns }
        },
        {
            state: useAppSelector(state => state.partnerDocuments),
            action: partnerDocumentsActions,
            config: { entityName: PartnerDocumentConstants.title_ns }
        }
    ], 'PartnerView');

    const partner = useAppSelector(state => state.partners.entity);
    const lastUpdated = useAppSelector(state => state.partners.lastUpdated);
    
    useEffect(() => {
        if (searchParams.get('view') === 'partner' && searchParams.get('id')) {
            if (searchParams.get('id') === 'new') {
                dispatch(partnersActions.createEmptyEntity({} as Partner));
            } else {
                dispatch(partnersActions.selectEntity({id: Number(searchParams.get('id'))}));
                // dispatch(vacanciesActions.fetchOne(Number(searchParams.get('id'))));    
            }
            
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, lastUpdated]);

    if (searchParams.get('view') === 'partner' && searchParams.get('id')) {
        return (
            partner &&
            <DrawerWidget 
                isOpen={searchParams.get('view') === 'partner' && searchParams.get('id') ? true : false}
                title={<PartnerHeadTitle blocked={false} />}
                topRightHeader={<PartnerHeadActions blocked={false} />}
                actions={{
                    clearEntity: partnersActions.clearEntity,
                }}
                childs={[
                    <PartnerView key={Number(searchParams.get('id'))} id={Number(searchParams.get('id'))} editable={true} />
                ]}
            />
        )
    }

    return null
};