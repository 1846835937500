import { BaseService } from '../../../shared/api/base';
import { Vacancy, CreateVacancyRequest } from '../model/types';

class VacancyService extends BaseService<Vacancy, CreateVacancyRequest> {
    constructor() {
        super('/vacancies');
    }
}

export const vacancyService = new VacancyService();
