import { FC } from 'react';
import { Breadcrumb } from 'flowbite-react';
import { IconBase } from 'react-icons';

export interface BreadcrumbItem {
    href?: string;
    text: string;
    icon?: typeof IconBase;
}

interface AppBreadcrumbsProps {
    items: BreadcrumbItem[];
    className?: string;
}

export const AppBreadcrumbs: FC<AppBreadcrumbsProps> = ({ items, className }) => {
    return (
        <Breadcrumb className={className}>
            {items.map((item, index) => (
                <Breadcrumb.Item
                    key={index}
                    href={item.href}
                    icon={item.icon}
                >
                    {item.text}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}
