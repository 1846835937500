import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddPartnerSchema } from './types';

const initialState: AddPartnerSchema = {
    isLoading: false,
    error: undefined,
};

export const addPartnerSlice = createSlice({
    name: 'addPartner',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
    },
});

export const { actions: addPartnerActions } = addPartnerSlice;
export const { reducer: addPartnerReducer } = addPartnerSlice;
