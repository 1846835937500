import { Partner } from "@/entities/partner";
import { PartnerReference } from "@/entities/partner/ui/PartnerReference";
import { RateType, RateTypeReference } from "@/entities/rate-type";
import { Stack } from "@/entities/stack";
import { StackReference } from "@/entities/stack/ui/StackReference";
import { Status } from "@/entities/status";
import { StatusReference } from "@/entities/status/ui/StatusReference";
import { vacanciesActions, Vacancy, VacancyConstants } from "@/entities/vacancy";
import { EditableBadgeField, EditableTextField, LabelComponent } from "@/shared";
import { apiGPT } from "@/shared/api/gpt";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { Box, Button, Checkbox, IconButton, List, ListItem, ListItemContent, ListSubheader, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { HiChevronDown, HiChevronRight, HiFire } from "react-icons/hi";
 
interface VacancyViewProps {
    id: number;
    editable?: boolean;
}

export const VacancyView = ({ id, editable }: VacancyViewProps) => {
    const dispatch = useAppDispatch();
    const statuses = useAppSelector(state => state.statuses.entities);

    const [ showDetails, setShowDetails ] = useState(true);
    const [ showLinks, setShowLinks ] = useState(true);
    const [ showDescription, setShowDescription ] = useState(true);
    const [ showPublicDescription, setShowPublicDescription ] = useState(true);
    const [ gptResult, setGptResult ] = useState<string | null>(null);


    const vacanciesState = useAppSelector(state => state.vacancies);
    const partnersState = useAppSelector(state => state.partners);
    const stacksState = useAppSelector(state => state.stacks);

    useEffect(() => {
        if (id) {
            dispatch(vacanciesActions.selectEntity({id: id}));
        }
    }, [id, vacanciesState.entities, dispatch]);

    function handleSelectRelated(query: Partner | Status | Stack | Stack[] | RateType, field: string) {
        dispatch(vacanciesActions.updateEntityState({...vacanciesState.entity, [field]: query} as Vacancy))
        if (id) {
            dispatch(vacanciesActions.updateEntity({id: id, data: {[field]: Array.isArray(query) ? query.map((q) => q.id) : (query.id || null)}}));
        }
    }

    function handleUpdateVacancy(query: string | number | boolean, field: string) {
        dispatch(vacanciesActions.updateEntityState({...vacanciesState.entity, [field]: query} as Vacancy))
        if (id) {
            dispatch(vacanciesActions.updateEntity({id: id, data: {[field]: query}}));
        }
    }

    async function handleCombDescription() {
        setGptResult("loading");
        try {
          const result = await apiGPT.combineDescription({
            prompt_key: 'vacancy_description',
            text: vacanciesState.entity?.description || ""
          })
          setGptResult(
            `Описание: ${result.data.project_description}\n  
      ${result.data.requirements && result.data.requirements.mandatory && result.data.requirements.mandatory && "Обязательные требования:"}
      ${result.data.requirements && result.data.requirements.mandatory && result.data.requirements.mandatory.map(m => "- "+m).join("\n")}\n\
      ${result.data.requirements && result.data.requirements.nice_to_have && result.data.requirements.nice_to_have && "Будет плюсом:"}
      ${result.data.requirements && result.data.requirements.nice_to_have.map(m => "- "+m).join("\n")}\n\
      ${result.data.responsibilities && "Задачи:"}
      ${result.data.responsibilities && result.data.responsibilities.map(m => "- "+m).join("\n")}`
          );
        } catch (error) {
          console.error(error);
          setGptResult("error");
        }
      };

    return (
    vacanciesState.entity &&
        <List>
            <ListSubheader>
                Детали вакансии
                <IconButton
                    size="sm"   
                    onClick={() => setShowDetails(!showDetails)}
                >
                    {showDetails ? (
                        <HiChevronDown/>
                    ) : (
                        <HiChevronRight/>
                    )}
                </IconButton>
            </ListSubheader>
            {showDetails && <List>
                <ListItem>
                    <ListItemContent>
                        <PartnerReference
                            onApply={(value: Partner) => {
                                handleSelectRelated(value, "author");
                            }}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(partnersState.entities.filter((entity: Partner) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                            partner={vacanciesState.entity?.author as Partner}
                            blocked={!editable}
                            labelComponent={
                                <LabelComponent>Автор</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.external_id}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "external_id")}
                            labelComponent={
                                <LabelComponent>Внешний ID</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <Box className="flex flex-row items-center gap-2">
                            <Box className='w-full grid grid-cols-5 items-baseline'>
                                <Box className="col-span-2 md:col-span-1">
                                    <LabelComponent>Публичная</LabelComponent>
                                </Box>
                                <Box className="col-span-3 md:col-span-4 px-4">
                                    <Checkbox 
                                        checked={vacanciesState.entity?.is_public}
                                        onChange={(e) => handleUpdateVacancy(e.target.checked, "is_public")}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <StatusReference 
                            status={vacanciesState.entity?.status as Status}
                            statuses={statuses.filter(status => status.relation === VacancyConstants.relation)}
                            onApply={(v: Status) => handleSelectRelated(v, "status")}
                            labelComponent={
                                <LabelComponent>Статус</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableBadgeField 
                            value={vacanciesState.entity?.priority}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "priority")}
                            badges={[
                                {
                                id: 1,
                                color: "failure",
                                name: (
                                    <div className="flex flex-row place-items-center space-x-1">
                                    <HiFire color="red" />
                                    Высокий
                                    </div>
                                ),
                                },
                                { id: 2, color: "warning", name: "Средний" },
                                { id: 3, color: "info", name: "Низкий" },
                            ]}
                            labelComponent={
                                <LabelComponent>Приоритет</LabelComponent>
                            }
                        /> 
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <StackReference 
                            stacks={vacanciesState.entity?.stacks as Stack[]}
                            blocked={!editable}
                            onApply={(value: Stack[]) => handleSelectRelated(value, "stacks")}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(stacksState.entities.filter((entity: Stack) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                            creatable={true}
                            labelComponent={
                                <LabelComponent>Стек</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <RateTypeReference 
                            rateType={vacanciesState.entity?.rate_type}
                            blocked={!editable}
                            onApply={(value: RateType) => handleSelectRelated(value, "rate_type")}
                            labelComponent={
                                <LabelComponent>Тип оплаты</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            numeric
                            value={vacanciesState.entity?.min_salary_rate as number}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "min_salary_rate")}
                            labelComponent={
                                <LabelComponent>Минимальная ставка</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            numeric
                            value={vacanciesState.entity?.max_salary_rate as number}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "max_salary_rate")}
                            labelComponent={
                                <LabelComponent>Максимальная ставка</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.location}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "location")}
                            labelComponent={
                                <LabelComponent>Локация исполнения</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.citizenship}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "citizenship")}
                            labelComponent={
                                <LabelComponent>Гражданство исполнителя</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.format}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "format")}
                            labelComponent={
                                <LabelComponent>Формат работы</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.vacancy_type}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "vacancy_type")}
                            labelComponent={
                                <LabelComponent>Тип занятости</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.project}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "project")}
                            labelComponent={
                                <LabelComponent>Проект аллокации</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.project_length}
                            blocked={!editable}
                            onApply={(value: string | number) => handleUpdateVacancy(value, "project_length")}
                            labelComponent={
                                <LabelComponent>Срок аллокации</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
            </List>}
            <ListSubheader>
                Связи
                <IconButton
                    size="sm"   
                    onClick={() => setShowLinks(!showLinks)}
                >
                    {showLinks ? (
                        <HiChevronDown/>
                    ) : (
                        <HiChevronRight/>
                    )}
                </IconButton>
            </ListSubheader>
            {showLinks && <List>
                <ListItem>
                    <ListItemContent>
                        <PartnerReference 
                            onApply={(value: Partner) => {
                                handleSelectRelated(value, "client");
                            }}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(partnersState.entities.filter((entity: Partner) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                            partner={vacanciesState.entity?.client as Partner}
                            blocked={!editable}
                            labelComponent={
                                <LabelComponent>Клиент</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <PartnerReference 
                            onApply={(value: Partner) => {
                                handleSelectRelated(value, "partner");
                            }}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(partnersState.entities.filter((entity: Partner) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                            partner={vacanciesState.entity?.partner as Partner}
                            blocked={!editable}
                            labelComponent={
                                <LabelComponent>Посредник</LabelComponent>
                            }
                        />  
                    </ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>
                        <PartnerReference 
                            onApply={(value: Partner) => {
                                handleSelectRelated(value, "contractor");
                            }}
                            onSearch={(query: string) => {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve(partnersState.entities.filter((entity: Partner) => entity.name.toLowerCase().includes(query.toLowerCase())));
                                    }, 1000);
                                });
                            }}
                            partner={vacanciesState.entity?.contractor as Partner}
                            blocked={!editable}
                            labelComponent={
                                <LabelComponent>Исполнитель</LabelComponent>
                            }
                        />
                    </ListItemContent>
                </ListItem>
            </List>}
            <ListSubheader>
                Описание от клиента
                <IconButton
                    size="sm"   
                    onClick={() => setShowDescription(!showDescription)}
                >
                    {showDescription ? (
                        <HiChevronDown/>
                    ) : (
                        <HiChevronRight/>
                    )}
                </IconButton>
            </ListSubheader>
            {showDescription && <List>
                <ListItem>
                    <ListItemContent>
                        <EditableTextField 
                            value={vacanciesState.entity?.description}
                            blocked={!editable}
                            multiline
                            onApply={(value: string | number) => handleUpdateVacancy(value, "description")}
                        />
                    </ListItemContent>
                </ListItem>
            </List>}            
            <ListSubheader>
                Публичное описание
                <IconButton
                    size="sm"   
                    onClick={() => setShowPublicDescription(!showPublicDescription)}
                >
                    {showPublicDescription ? (
                        <HiChevronDown/>
                    ) : (
                        <HiChevronRight/>
                    )}
                </IconButton>
            </ListSubheader>
            {showPublicDescription && <List>
                <ListItem>
                    <ListItemContent>
                    {vacanciesState.entity?.description && <Button
                            loading={gptResult==="loading"}
                            onClick={handleCombDescription} 
                            size="sm"
                            variant="plain"
                        >
                            Генерировать описание
                        </Button>}
                        <Box className="border-2 rounded-md p-4 w-full flex flex-col" sx={{
                            display: gptResult && gptResult !== "loading" ? 'block' : 'none'
                        }}>  
                            <Typography level="body-sm">Предлагаемый результат:</Typography>
                            <EditableTextField
                                activeDefault={true}
                                multiline={true}
                                value={gptResult as string}
                                onApply={(v) => {handleUpdateVacancy(v, "alter_description"); setGptResult(null)}}
                                onCancel={() => setGptResult(null)}
                                className="text-sm"
                            />
                        </Box>
                        <div className="text-md text-gray-900 pt-2">
                            <EditableTextField
                            multiline={true}
                            value={vacanciesState.entity?.alter_description}
                            onApply={(v) => handleUpdateVacancy(v, "alter_description")}
                            className="text-sm"
                            />
                        </div>
                    </ListItemContent>  
                </ListItem>
            </List>}
        </List>
    );
};