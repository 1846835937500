import { useUnit } from 'effector-react';
import { $notifications } from '../../lib/notifications/model/store';
import { NotificationItem } from './NotificationItem';

export const NotificationsContainer = () => {
    const notifications = useUnit($notifications);

    return (
        <div className="fixed top-4 right-4 z-50 min-w-[300px] max-w-[400px]">
            {notifications.map((notification) => (
                <NotificationItem
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </div>
    );
};
