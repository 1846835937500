import { AppBreadcrumbs, BreadcrumbItem } from "@/shared/ui/breadcrumb"
import { Box, Typography } from "@mui/joy";

interface AdminPageHeaderProps {
    title: string;
    breadcrumbs: BreadcrumbItem[];
    children?: React.ReactNode;
}

export const AdminPageHeader = ({ title, breadcrumbs, children }: AdminPageHeaderProps) => {
    return (
        <div>
            <div className="mb-4">
                <AppBreadcrumbs 
                    items={[
                        ...breadcrumbs,
                        { text: title}
                    ]}
                />
            </div>
            <Box className="flex mb-1 gap-1 sm:flex-col md:flex-row sm:items-start md:items-center flex-wrap justify-between">
                <Typography level="h2" component="h1">
                    {title}
                </Typography>
            </Box>
            <Box className="space-x-2 pt-4">
                {children}
            </Box>
        </div>
    )
}