import { EditableMultiLinkField } from "@/shared";
import { Stack } from "../model/types";
import { stacksActions } from "../model/slice";
import { useAppDispatch } from "@/shared/lib/hooks";

interface StackReferenceProps {
    stacks: Stack[]; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (values: Stack[]) => void;
    onSearch: (query: string) => Promise<Stack[] | string[]>;
    creatable?: boolean
};

export const StackReference = ({stacks, blocked, labelComponent, onApply, onSearch, creatable}: StackReferenceProps) => {
    const dispatch = useAppDispatch();
    const handleCreate = async (value: any) => {
        return dispatch(stacksActions.createEntity({name: value}));
    }
    return (
        <EditableMultiLinkField 
            value={stacks}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch}
            onApply={(values: Stack[]) => onApply(values)}
            onCreate={creatable ? handleCreate : undefined}
        />
    )
};