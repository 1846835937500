import { GlobalStyles, Modal, ModalClose, ModalDialog } from "@mui/joy"
import React from "react";

export interface ModalWidgetProps {
    className?: string;
    open: boolean
    onClose: () => void
}

export const ModalWidget = ( props: React.PropsWithChildren<ModalWidgetProps> ) => {
    
    return (
        <React.Fragment>
            <Modal 
                sx={{zIndex: 999}}
                open={props.open} 
                onClose={props.onClose}
                className={props.className && props.className}>
                    <React.Fragment>
                        <ModalClose />
                        <ModalDialog
                            layout="center"
                            role="dialog"
                            aria-labelledby="nested-modal-title"
                            aria-describedby="nested-modal-description"
                            sx={(theme) => ({
                                [theme.breakpoints.only('sm')]: {
                                top: 'unset',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                borderRadius: 0,
                                transform: 'none',
                                maxWidth: 'unset',
                                },
                            })}
                        >
                            {props.children}
                        </ModalDialog>    
                    </React.Fragment>
            </Modal>    
        </React.Fragment>
        
    );
};