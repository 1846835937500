import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/shared/lib/hooks/useAppDispatch';
import { selectAddPartnerIsLoading, selectAddPartnerError } from '../model/selector';
import { addPartnerActions } from '../model/slice';
import { Partner, partnersActions } from '@/entities/partner';
import { Status } from '@/entities/status';
import { PartnerType } from '@/entities/partner-type';
import { PartnerForm } from '@/entities/partner/ui/PartnerForm';
import { addNotificationFx } from '@/shared/lib/notifications/model/store';

export interface AddPartnerFormProps {
    statuses: Status[];
    partnerTypes: PartnerType[];
    onSuccess: () => void;
    onDecline: () => void
}

export const AddPartnerForm: FC<AddPartnerFormProps> = ({ statuses, partnerTypes, onSuccess, onDecline }) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectAddPartnerIsLoading);
    const error = useSelector(selectAddPartnerError);

    const handleSubmit = async (data: Partial<Partner>) => {
        const request = {
            name: data.name,
            types: data.types,
            status: data.status?.id,
        }
        try {
            dispatch(addPartnerActions.setLoading(true));
            await dispatch(partnersActions.createEntity(request as Partner)).unwrap();
            onSuccess?.();
        } catch (error) {
            if (error instanceof Error) {
                dispatch(addPartnerActions.setError(error.message));
            } else {
                dispatch(addPartnerActions.setError('An unknown error occurred'));
            }
        } finally {
            dispatch(addPartnerActions.setLoading(false));
        }
    };

    useEffect(() => {
        if (error) {
            addNotificationFx({
                message: error,
                type: 'danger',
            });
        }
    }, [error]);

    return (
        <PartnerForm
            onSubmit={handleSubmit}
            statuses={statuses}
            partnerTypes={partnerTypes}
            isLoading={isLoading}
            onDecline={onDecline}
        />
    );
};   
