import { BaseService } from '../../../shared/api/base';
import { CreateScoringRequest, Scoring, ScoringAnswer, ScoringCriteria } from '../model/types';

class ScoringService extends BaseService<Scoring, CreateScoringRequest> {
    constructor() {
        super('/scorings');
    }

    async getByRecruitment(recruitmentId: string): Promise<Scoring[]> {
        return this.client.get(`/recruitments/${recruitmentId}/scorings`);
    }

    async getCriteria(): Promise<ScoringCriteria[]> {
        return this.client.get('/scoring-criteria');
    }

    async createCriteria(data: Omit<ScoringCriteria, 'id'>): Promise<ScoringCriteria> {
        return this.client.post('/scoring-criteria', data);
    }

    async updateCriteria(id: string, data: Partial<ScoringCriteria>): Promise<ScoringCriteria> {
        return this.client.patch(`/scoring-criteria/${id}`, data);
    }

    async deleteCriteria(id: string): Promise<void> {
        return this.client.delete(`/scoring-criteria/${id}`);
    }

    async getAnswers(scoringId: string): Promise<ScoringAnswer[]> {
        return this.client.get(`${this.baseUrl}/${scoringId}/answers`);
    }

    async createAnswer(scoringId: string, data: Omit<ScoringAnswer, 'id' | 'scoring_id'>): Promise<ScoringAnswer> {
        return this.client.post(`${this.baseUrl}/${scoringId}/answers`, data);
    }

    async updateAnswer(scoringId: string, answerId: string, data: Partial<ScoringAnswer>): Promise<ScoringAnswer> {
        return this.client.patch(`${this.baseUrl}/${scoringId}/answers/${answerId}`, data);
    }

    async deleteAnswer(scoringId: string, answerId: string): Promise<void> {
        return this.client.delete(`${this.baseUrl}/${scoringId}/answers/${answerId}`);
    }
}

export const scoringService = new ScoringService();
