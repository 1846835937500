import { vacanciesActions, Vacancy } from "@/entities/vacancy";
import { EditableTextField } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { IconButton, Typography } from "@mui/joy";
import { HiEye, HiSave } from "react-icons/hi";

interface VacancyHeadTitleProps {
  blocked?: boolean;
}

export const VacancyHeadTitle = ({blocked}: VacancyHeadTitleProps) => {
    const vacancy = useAppSelector(state => state.vacancies.entity as Vacancy);
    const dispatch = useAppDispatch();
    
    const handleUpdateVacancy = (value: string | number, field: string) => {
        if (!vacancy) return;
        if (!vacancy.id) {
            dispatch(vacanciesActions.updateEntityState({...vacancy, [field]: value} as Vacancy));  
        } else {
            dispatch(vacanciesActions.updateEntity({id: vacancy.id, data: {[field]: value}}));  
        }
        
    };
    return (
        <Typography component={'div'} className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {vacancy && <EditableTextField
            value={vacancy.title as string}
            blocked={blocked}
            onApply={(v) => {
                handleUpdateVacancy(v, "title");
            }}
            />}
        </Typography>
    )
}

export const VacancyHeadActions = () => {
    const vacancy = useAppSelector(state => state.vacancies.entity as Vacancy);
    const dispatch = useAppDispatch();
    const handleCreateVacancy = () => {
        if (!vacancy) return;
        if (vacancy.id) return;
        if (vacancy.title === "Новая вакансия") {
          window.alert("Название вакансии обязательно!");
          return;
        }
        if (vacancy?.rate_type?.name === "NULL") {
          window.alert("Укажите тип рейта вакансии");
          return;
        }
        if (!vacancy.client) {
          window.alert("Укажите клиента");
          return;
        }
        if (!vacancy.partner) {
          window.alert("Укажите партнера");
          return;
        }
        if (vacancy?.stacks?.length === 0) {
          window.alert("Укажите стэк вакансии");
          return;
        }
        dispatch(vacanciesActions.createEntity(vacancy as Partial<Vacancy>));
    };

    return (
        vacancy.id ? (
            <div className="flex flex-row gap-3 justify-end items-center">
              <div className="flex flex-col gap-2">
                <Typography
                  level="body-sm"
                  gutterBottom
                  className="text-gray-500"
                >
                  Дата публикации:{" "}
                  {new Date(vacancy.created_at).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
                <Typography
                  level="body-sm"
                  gutterBottom
                  className="text-gray-500"
                >
                  Дата обновления:{" "}
                  {new Date(vacancy.updated_at).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>  
              </div>
              
              <IconButton 
                size="sm" 
                variant="soft"
                onClick={() => {
                  window.open(`${window.location.origin}/jobs?query=${vacancy.job_hash}`, '_blank')
                }}  
              >
                <HiEye/>
              </IconButton>
            </div>
          ) : (
            <IconButton
              size="lg"
              variant="solid"
              onClick={() => handleCreateVacancy()}
            >
              <HiSave />
            </IconButton>
          )
    )
};