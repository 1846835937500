import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { Box, Typography } from "@mui/joy";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { Drawer } from "flowbite-react";
import React from "react";
import { useSearchParams } from "react-router-dom";

interface DrawerProps {
    isOpen: boolean;
    title: React.ReactNode | string;
    topRightHeader?: React.ReactNode;
    actions: {
        clearEntity: ActionCreatorWithoutPayload<`${string}/clearEntity`>;
    },
    childs: React.ReactNode[]
}

export const DrawerWidget: React.FC<DrawerProps> = ({ isOpen, title, topRightHeader, actions, childs }) => {
    const dispatch = useAppDispatch();
    const [ searchParams, setSearchParams ] = useSearchParams();

    const onClose = () => {
        dispatch(actions.clearEntity());
        const search = searchParams.get('search');
        if (search) {
            setSearchParams({search});
        } else {
            setSearchParams({});
        }
    };
    return (
        <Drawer
            open={isOpen}
            onClose={onClose}
            position="right"
            className="w-11/12 md:w-2/3 pt-[var(--Header-height)] md:pt-0 md:mt-0"
        >
            <div className="grid grid-cols-5 justify-items-stretch place-items-center">
                <Typography component={'div'} className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                    {title}
                </Typography>
                {topRightHeader && <Box className="w-full col-span-2">
                    {topRightHeader}
                </Box>}
            </div>
            <div className="flex flex-col pt-8 gap-8">
                {childs}
            </div>
        </Drawer>
    );
};