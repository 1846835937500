import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddProcessingSchema } from './types';

const initialState: AddProcessingSchema = {
    isLoading: false,
    error: undefined,
};

export const addProcessingSlice = createSlice({
    name: 'addProcessing',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
    },
});

export const { actions: addProcessingActions } = addProcessingSlice;
export const { reducer: addProcessingReducer } = addProcessingSlice;
