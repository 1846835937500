import React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { theme } from '../shared/config/theme';
import { AppRouter } from './router';
import { StoreProvider } from './providers/StoreProvider';

export const App: React.FC = () => {
    return (
        <StoreProvider>
            <CssVarsProvider theme={theme}>
                <CssBaseline />
                <AppRouter />
            </CssVarsProvider>    
        </StoreProvider>         
        
    );
};

export default App;
