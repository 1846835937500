import { EditableLinkField, EditableMultiLinkField } from "@/shared";
import { Partner } from "../model/types";
import { useAppSelector } from "@/shared/lib/hooks";

interface PartnerLinkReferenceProps {
    partners: Partner[]; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (values: Partner[]) => void;
    onSearch?: (query: string) => Promise<Partner[] | string[]>;
};

interface PartnerReferenceProps {
    partner?: Partner; 
    blocked?: boolean;
    labelComponent?: React.ReactNode
    onApply: (value: Partner) => void;
    onSearch?: (query: string) => Promise<Partner[] | string[]>;
    onCreate?: (value: string) => Promise<Partner>;
};

export const PartnerListReference = ({partners, blocked, labelComponent, onApply, onSearch}: PartnerLinkReferenceProps) => {
    const partnersState = useAppSelector(state => state.partners.entities);
    const search = async (query: string) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(partnersState.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
            }, 1000);
        }) as Promise<Partner[]>;
    }
    return (
        <EditableMultiLinkField 
            value={partners}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch || search}
            onApply={(values: Partner[]) => onApply(values)}
        />
    )
};

export const PartnerReference = ({partner, blocked, labelComponent, onApply, onSearch, onCreate}: PartnerReferenceProps) => {
    const partners = useAppSelector(state => state.partners.entities);
    const search = async (query: string) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(partners.filter((entity) => entity.name.toLowerCase().includes(query.toLowerCase())));
            }, 1000);
        }) as Promise<Partner[]>;
    }
    
    return (
        <EditableLinkField 
            value={partner}
            blocked={blocked}
            labelComponent={labelComponent}
            onSearch={onSearch || search}
            onCreate={onCreate}
            onApply={(value: Partner) => onApply(value)}
        />
    )
};