import React from 'react';
import { useUnit } from 'effector-react';
import { $authStore } from '../model/store';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const auth = useUnit($authStore);

    if (!auth.isAuthenticated) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <h2 className="text-xl font-semibold mb-2">Проверяем аутентификацию...</h2>
                    <p className="text-gray-600">Пожалуйста, подождите...</p>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};
