import { TableAdmin } from "@/shared";
import { useAppDispatch, useAppSelector } from "@/shared/lib/hooks";
import { partnerContactsActions } from "../model/slice";
import { Contact } from "../model/types";

interface ContactTableProps {
    partnerId: number,
    onEditModalAction: (contact: Contact) => void
    editable?: boolean
}

export const ContactTable = ({ partnerId, onEditModalAction, editable}: ContactTableProps) => {
    const dispatch = useAppDispatch();

    const partnerContactsState = useAppSelector(state => state.partnerContacts);

    return (
        <TableAdmin 
            data={partnerContactsState.entities.filter(c => c.partner_id === partnerId)}
            columns={[
                {name: 'name', alias: 'Имя'},
                {name: 'email', alias: 'Email'},
                {name: 'phone', alias: 'Телефон'},
            ]}
            {...editable && {onEdit:(id: string | number) => onEditModalAction(partnerContactsState.entities.find(c => c.id === id) as Contact)}}
            {...editable && {onDelete: (id: string |number) => dispatch(partnerContactsActions.deleteEntity(id as number))}}
        />
    )
};