import { AxiosInstance, AxiosResponse } from "axios";
import { baseApi } from "./base";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

interface AIRequest {
    prompt_key: "vacancy_description";
    text: string;
}

interface AIResponse {
    project_description: string;
    requirements: {
        mandatory: string[];
        nice_to_have: string[];
    };
    responsibilities: string[];
}

class AIService {
    protected client: AxiosInstance

    constructor() {
        this.client = baseApi;
        this.client.defaults.withCredentials = true;
    }

    post<T = any>(url: string, data: AIRequest): Promise<AxiosResponse<T>> {
        return this.client.post<T>(url, data);
    };
}

export const apiGPT = {
    combineDescription: (data: AIRequest): Promise<AxiosResponse<AIResponse>> =>
        new AIService().post<AIResponse>(`${API_BASE_URL}/utils/openai`, data)
};