import { createStore, createEvent, createEffect } from 'effector';
import { AuthState } from '@/entities/user/model/types';
import { authApi } from '@/shared/api/auth';
import axios from 'axios';
import { useUnit } from 'effector-react';

// Initial state
const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    token: null,
};

// Events
export const logout = createEvent();
export const setAuthState = createEvent<AuthState>();

// Effects
export const checkAuthStatusFx = createEffect(async () => {
    try {
        const response = await authApi.checkStatus();
        const { isAuthenticated, user } = response.data;
        
        if (!isAuthenticated) {
            sessionStorage.setItem('initialPage', window.location.pathname + window.location.search);
            const loginResponse = await authApi.initiateLogin(window.location.origin + '/oidc-callback');
            const { loginUrl, tokenUrl } = loginResponse.data;
            sessionStorage.setItem('oidcState', JSON.stringify({ tokenUrl }));
            window.location.replace(loginUrl);
            return null;
        }
        
        
        return { isAuthenticated, user };
    } catch (error) {
        console.error('Auth check failed:', error);
        throw error;
    }
});

export const handleCallbackFx = createEffect(async (params: URLSearchParams) => {
    try {
        const oidcState = sessionStorage.getItem('oidcState');
        if (!oidcState) throw new Error('No OIDC state found');
        
        const { tokenUrl } = JSON.parse(oidcState);
        const state = params.get('state');
        const code = params.get('code');
        
        if (!state || !code) throw new Error('Invalid callback parameters');
        
        const response = await authApi.handleCallback(tokenUrl, code, state);
        const { accessToken, user } = response.data;
        
        return { isAuthenticated: true, user, token: accessToken};
    } catch (error) {
        console.error('Callback handling failed:', error);
        sessionStorage.removeItem('oidcState');
        return initialState;
    }
});

export const logoutFx = createEffect(async () => {
    const response = await authApi.logout();
    if (response.data.logoutUrl) {
        window.location.replace(response.data.logoutUrl);
    }
    return initialState;
});

// Store
export const $authStore = createStore<AuthState>(initialState)
    .on(setAuthState, (_, newState) => newState)
    .on(logout, () => initialState)
    .on(handleCallbackFx.doneData, (_, data) => data || initialState)
    .on(checkAuthStatusFx.doneData, (_, data) => data ? { ...initialState, ...data } : initialState);

// Hook for accessing auth store
export const useAuthStore = () => {
    const store = useUnit($authStore);

    const setAuth = (isAuthenticated: boolean) => {
        setAuthState({ ...store, isAuthenticated });
    };

    const setUser = (user: any) => {
        setAuthState({ ...store, user });
    };

    return {
        ...store,
        setAuth,
        setUser,
    };
};

// Setup axios interceptors
axios.interceptors.request.use(config => {
    const state = $authStore.getState();
    if (state.token) {
        config.headers.Authorization = `Bearer ${state.token}`;
    }
    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401) {
            logout();
        }
        return Promise.reject(error);
    }
);
