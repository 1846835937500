import { BASE_NEW_RATE_TYPE } from "@/entities/rate-type";
import { CreateVacancyRequest } from "../model/types";

const ns = "vacancy";
const relation = "job_vacancy";

const acitons = {
    create: "Создать вакансию",
    edit: "Редактировать вакансию",
    view: "Просмотреть вакансию",
};

// namespace title
const title_ns = "Вакансии";

const title = "Вакансия";

export const VacancyConstants = {
    ns,
    relation,
    title_ns,
    title,
    acitons,
};

export const emptyVacancy: CreateVacancyRequest = {
    title: "Новая вакансия",
    description: "Описание",
    alter_description: "",
    min_salary_rate: 0,
    max_salary_rate: 0,
    rate_type: BASE_NEW_RATE_TYPE,
    vacancy_type: "Fulltime",
    project: "Проект",
    project_length: "Бессрочний",
    priority: 2,
    location: "РФ",
    citizenship: "РФ",
    format: "Remote",
    stacks: [],
};