import { BaseService } from '../../../shared/api/base';
import { User } from '../model/types';

export class UsersService extends BaseService<User, User> {
  constructor() {
    super('/users');
  }

  async fetchAll(): Promise<User[]> {
    return this.client.get(this.baseUrl);
  }
}
