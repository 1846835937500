import { FC, FormEvent } from 'react';
import { Label, TextInput, Badge } from 'flowbite-react';
import { Status } from '@/entities/status';
import { PartnerType } from '@/entities/partner-type';
import { Option, Select, Button } from '@mui/joy';
import { Partner } from '../model/types';


export interface PartnerFormProps {
    onSubmit: (data: Partial<Partner>) => Promise<void>;
    onDecline?: () => void;
    initialData?: Partial<Partner>;
    statuses: Status[];
    partnerTypes: PartnerType[];
    isLoading?: boolean;
}

export const PartnerForm: FC<PartnerFormProps> = ({
    onSubmit,
    initialData,
    statuses,
    partnerTypes,
    isLoading,
    onDecline,
}) => {
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        
        await onSubmit({
            name: formData.get('name') as string,
            types: formData.getAll('types').map(id => ({ id: Number(id) }) as PartnerType),
            status: {id: Number(formData.get('status'))} as Status,
        });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Название" />
                </div>
                <TextInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Название партнера"
                    required
                    disabled={isLoading}
                    defaultValue={initialData?.name}
                />
            </div>
            
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="types" value="Типы" />
                </div>
                <Select
                    id="types"
                    name="types"
                    multiple
                    disabled={isLoading}
                    defaultValue={initialData?.types}
                >
                    {partnerTypes.map((type) => (
                        <Option key={type.id} value={type.id}>
                            {type.name}
                        </Option>
                    ))}
                </Select>
            </div>

            <div>
                <div className="mb-2 block">
                    <Label htmlFor="status" value="Статус" />
                </div>
                <Select
                    id="status"
                    name="status"
                    disabled={isLoading}
                    defaultValue={initialData?.status?.id}
                >
                    {statuses.map((status) => (
                        <Option key={status.id} value={status.id}>
                            <Badge color={status.color}>{status.name}</Badge>
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="col-span-2 flex justify-end gap-2">
                <Button
                    type="button"
                    onClick={onDecline}
                    disabled={isLoading}
                    color="neutral"
                >
                    Отмена
                </Button>
                
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
