import React from 'react';
import { Box, GlobalStyles } from '@mui/joy';
import { Sidebar } from '../../sidebar/ui/Sidebar';
import { useUnit } from 'effector-react';
import { $loading } from '../model/store';
import { StateManager } from '../../state-manager/ui/StateManager';
import { Navbar } from '../../navbar/ui/Navbar';

interface LayoutProps {
    children: React.ReactNode;
}



export const Layout: React.FC<LayoutProps> = ({ children }) => {
    const loading = useUnit($loading);

    return (
        <Box className="flex min-h-dvh">
            <GlobalStyles
                styles={(theme) => ({
                ':root': {
                    '--Header-height': '52px',
                    [theme.breakpoints.up('md')]: {
                    '--Header-height': '0px',
                    },
                    '--Sidebar-width': '220px',
                    [theme.breakpoints.up('lg')]: {
                    '--Sidebar-width': '240px',
                    },
                },
                })}
            />
            <Navbar />
            <Sidebar />
            {loading && (
                <Box className="z-50 absolute w-full h-full backdrop-blur-md flex justify-center items-center">
                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
                </Box>
            )}
            <Box
                component="main"
                className="MainContent flex-1 px-6 md:px-6 pb-2 md:pb-3 pt-4 flex flex-col min-w-0 h-dvh gap-1"
                sx={{
                    marginLeft: {xs: '0px', md: 'var(--Sidebar-width)'},
                    paddingTop: 'calc(var(--Header-height) + 1rem)',
                }}
            >
                {children}
            </Box>
            <StateManager />
        </Box>
    );
};
