import { BaseService } from '@/shared/api/base';
import { Partner, CreatePartnerRequest } from '../model/types';

class PartnerService extends BaseService<Partner, CreatePartnerRequest> {
    constructor() {
        super('/partners');
    }
}

export const partnerService = new PartnerService();
