import {
    Component, ErrorInfo, ReactNode,
} from 'react';
import { addNotificationFx } from '@/shared/lib/notifications/model/store';
import { NotificationsContainer } from '@/shared/ui/Notifications/NotificationsContainer';

interface ErrorBoundaryProps {
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError?: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        addNotificationFx({
            message: error.message || 'Произошла непредвиденная ошибка',
            type: 'danger',
        });
        console.log(error, errorInfo);
    }

    render() {
        const { children } = this.props;

        // if (hasError) {
        //     return (
        //         <>
        //             <NotificationsContainer />
        //             {/* <Error /> */}
        //         </>
        //     );
        // }

        return (
            <>
                <NotificationsContainer />
                {children}
            </>
        );
    }
}

export {
    ErrorBoundary,
};