import { BaseService } from '@/shared/api/base';
import { CreateDocumentRequest, Document, UploadDocumentRequest } from '../model/types';

class PartnerDocumentService extends BaseService<Document, CreateDocumentRequest | UploadDocumentRequest> {
    constructor() {
        super('/partners/documents');
    }

    async upload(data: UploadDocumentRequest) {
        return this.client.post(`${this.baseUrl}/upload`, data, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }
}

export const partnerDocumentService = new PartnerDocumentService();
