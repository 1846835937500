import { createStore, createEvent } from 'effector';

// События
export const toggleColumnCollapse = createEvent<string>();
export const setColumnCollapse = createEvent<{ columnId: string; isCollapsed: boolean }>();

// Типы
interface CollapsedColumnsState {
  [columnId: string]: boolean;
}

// Начальное состояние
const initialState: CollapsedColumnsState = {};

// Store
export const $collapsedColumns = createStore<CollapsedColumnsState>(initialState)
  .on(toggleColumnCollapse, (state, columnId) => ({
    ...state,
    [columnId]: !state[columnId]
  }))
  .on(setColumnCollapse, (state, { columnId, isCollapsed }) => ({
    ...state,
    [columnId]: isCollapsed
  }));
